<template>
    <button class="button is-small is-danger" @click="confirmStopCampaign">Stop</button>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import Buefy from "buefy";
export default Vue.component("campaign-stopper", {
    props: {
        campaign: {
            type: String,
            default: null
        },
        campaign_name: {
            type: String,
            default: null          
        }
    },
    methods: {
      confirmStopCampaign() {
          this.$buefy.dialog.confirm({
              title: `Stop Campaign "${this.campaign_name}"`,
              message: 'Are you sure you want to <b>stop</b> this campaign? This action cannot be undone.',
              confirmText: 'Stop Campaign',
              type: 'is-danger',
              hasIcon: true,
              onConfirm: () => {                
                this.$store.dispatch("CampaignStore/stopCampaign", {
                  id: this.campaign
                });
              }
              
          })
      }
    }
});
</script>