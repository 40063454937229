<template>
  <div>    
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
        <b-icon
            pack="fas"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
    </b-loading>
    <div class="columns is-multiline is-vcentered">
      <div class="column">
        <b-field label="period" label-position="on-border">
          <b-datepicker
            v-model="choosed.period"
            size="is-small"
            ref="datepicker"
            expanded
            icon="calendar"
            placeholder="Select a date"
            range
          ></b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <b-field label="campaigns" label-position="on-border">
          <b-select
            v-model="choosed.campaigns"
            expanded
            placeholder="Select a campaign"
            size="is-small"
          >
            <option
              :value="
                campaigns.map((c) => {
                  return c.id;
                })
              "
            >
              All
            </option>
            <option
              v-for="option in campaigns"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column" v-if="account != 'bronze'">
        <b-field label="database" label-position="on-border">
          <b-select
            v-model="choosed.databases"
            placeholder="Select a db"
            size="is-small"
            expanded
          >
            <option
              :value="
                databases.map((c) => {
                  return c.id;
                })
              "
            >
              All
            </option>
            <option
              v-for="option in databases"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Advertiser" label-position="on-border">
          <b-select
            v-model="choosed.advertisers"
            placeholder="Select an advretiser"
            size="is-small"
            expanded
          >
            <option
              :value="
                advertisers.map((c) => {
                  return c.id;
                })
              "
            >
              All
            </option>
            <option
              v-for="option in advertisers"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Category" label-position="on-border">
          <b-select
            v-model="choosed.categories"
            placeholder="Select a category"
            size="is-small"
            expanded
          >
            <option
              :value="
                categories.map((c) => {
                  return c.id;
                })
              "
            >
              All
            </option>
            <option
              v-for="option in categories"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name_en }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-1">
        <b-field>
          <b-button @click="clearForm" size="is-small" icon-left="times-circle">
            Clear
          </b-button>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column is-10">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th class="is-size-7">SENT ON</th>
              <th class="is-size-7">CAMPAIGN</th>
              <th class="is-size-7">LIST</th>
              <th class="is-size-7 has-text-centered">SENT</th>
              <th class="is-size-7 has-text-centered">OPEN</th>
              <th class="is-size-7 has-text-centered">
                <abbr title="Uniques open">U OPEN</abbr>
              </th>
              <th class="is-size-7 has-text-centered">CLICK</th>
              <th class="is-size-7 has-text-centered">
                <abbr title="Unique clicks">U CLICK</abbr>
              </th>
              <th class="is-size-7 has-text-centered">
                <abbr title="Open rate">OR</abbr>
              </th>
              <th class="is-size-7 has-text-centered">CTR</th>
              <th class="is-size-7 has-text-centered">COP</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="`row_${index}`" v-for="(result, index) in results">
              <td class="is-size-7">{{ result.sented_on }}</td>
              <td class="is-size-7">
                <b-tooltip
                  type="is-white"
                  position="is-right"
                  size="is-large"
                  multilined
                >
                  {{ result.campaign_name }}
                  <template v-slot:content>
                    <div class="has-text-left">
                      <ul>
                        <li><strong>id:</strong> {{ result.campaign_id }}</li>
                        <li>
                          <strong>subject:</strong>
                          {{ result.subject.substring(0, 50) }}
                        </li>
                        <!-- <li><strong>sent on:</strong> {{ formatDateTime(result.scheduling_traffic[0].date) }}</li> -->
                      </ul>
                    </div>
                  </template>
                </b-tooltip>
              </td>
              <td class="is-size-7">{{ result.list_name }}</td>
              <td class="is-size-7 has-text-centered">{{ result.sent }}</td>
              <td class="is-size-7 has-text-centered">{{ result.open }}</td>
              <td class="is-size-7 has-text-centered">{{ result.uopens }}</td>
              <td class="is-size-7 has-text-centered">{{ result.click }}</td>
              <td class="is-size-7 has-text-centered">{{ result.uclick }}</td>
              <td class="is-size-7 has-text-centered">{{ result.or }} %</td>
              <td class="is-size-7 has-text-centered">{{ result.ctr }} %</td>
              <td class="is-size-7 has-text-centered">{{ result.cop }} %</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-2">
        <div class="card p-4">
          <div class="card-content">
            <nav class="level">
              <!-- Left side -->
              <div class="level-left">
                <div class="level-item">
                  <p class="heading">Click:</p>
                </div>
              </div>
              <!-- Right side -->
              <div class="level-right">
                <div class="level-item">
                  <p class="title is-4">{{ getTotal("click") }}</p>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="card p-4">
          <div class="card-content">
            <nav class="level">
              <!-- Left side -->
              <div class="level-left">
                <div class="level-item">
                  <p class="heading">Open:</p>
                </div>
              </div>
              <!-- Right side -->
              <div class="level-right">
                <div class="level-item">
                  <p class="title is-4">{{ getTotal("open") }}</p>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="card p-4">
          <div class="card-content">
            <nav class="level">
              <!-- Left side -->
              <div class="level-left">
                <div class="level-item">
                  <p class="heading">Sent:</p>
                </div>
              </div>
              <!-- Right side -->
              <div class="level-right">
                <div class="level-item">
                  <p class="title is-4">{{ getTotal("sent") }}</p>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
      return {          
          isFullPage: true
      }
  },
  props: {
    account: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({      
      choosed: (state) => state.ReportStore.choosed,
      isLoading: (state) => state.ReportStore.isLoading,
      results: (state) => state.ReportStore.data,
      campaigns: (state) => state.CampaignStore.campaigns,
      advertisers: (state) => state.CampaignStore.advertisers,
      categories: (state) => state.AnagStore.categories,
      databases: (state) => state.AnagStore.databases,
      cleaned: (state) => state.ReportStore.cleaned
    }),
  },
  watch: {
    choosed: {
      deep: true,
      async handler() {      
        if(
          this.choosed.period != null || 
          this.choosed.campaigns != null || 
          this.choosed.advertisers != null ||  
          this.choosed.categories  != null ||
          this.choosed.databases  != null 
        )
        {
          await this.$store.dispatch("ReportStore/updateReportData", this.choosed);        
        }                   
      },
    },
  },
  mounted() {
    this.$store.dispatch("CampaignStore/listSentsCampaigns");
    this.$store.dispatch("CampaignStore/listAdvertisers");
    this.$store.dispatch("AnagStore/listCategories");
    this.$store.dispatch("AnagStore/listDatabases");
  },
  methods: {
    getTotal(type) {
      return this.results
        .map((res) => {
          return res[type];
        })
        .reduce((acc, val) => {
          return acc + val;
        }, 0);
    },
    formatDateTime(datetime) {
      return moment(datetime).format("DD/MM/YYYY HH:mm");
    },
    clearForm() {
      this.$store.dispatch("ReportStore/cleanForm");
    },
      openLoading() {
          this.isLoading = true
          setTimeout(() => {
              this.isLoading = false
          }, 10 * 1000)
      }    
  },
};
</script>