<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">AUDIENCE</p>
            <a
                v-if="!disabled"
                @click="$emit('delete-box')"
                class="card-header-icon has-text-grey-dark"
                aria-label="more options"
            >
                <span class="icon">
                    <i class="fas fa-times-circle" aria-hidden="true"></i>
                </span>
            </a>
        </header>
        <div class="card-content">
            <div class="content">
                <template v-for="(row, index) in box.filters">
                    <div
                        v-bind:key="`and_divider_${index}`"
                        class="is-divider"
                        data-content="AND"
                        v-if="index > 0"
                    ></div>
                    <filter-row
                        :disabled="disabled"
                        v-bind:key="`filterow_${row.uuid}`"
                        :filter="row">
                    </filter-row>
                </template>

                <div class="has-text-centered" v-if="!disabled">
                    <section>
                        <b-dropdown append-to-body aria-role="list">
                            <button class="button" slot="trigger">
                                <b-icon icon="plus"></b-icon>
                                <span>ADD FILTER</span>
                            </button>

                            <b-dropdown-item
                                :key="`addbutton_${ft}`"
                                v-for="ft in contexts"
                                aria-role="listitem"
                                @click="addFilter(ft)"
                            >Add {{ ft | titleize }}</b-dropdown-item>
                        </b-dropdown>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterRow from "./filter-row";
import { mapState } from "vuex";
export default {
    props: {
        box: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }        
    },
    data() {
        return {
            state: this.$store.state.SegmentStore
        };
    },
    computed: {
        ...mapState({
            segment: state => state.SegmentStore.segment,
            filter_contexts: state => state.FilterStore.contexts
        }),
        contexts() {
            return Object.keys(this.filter_contexts);
        },
        current_box(){
           return this.segment.audience_boxes.filter(i => {
               i.uuid == this.uuid
           })
        }
    },
    methods: {
        addFilter(ctx) {
            let filter_row = {
                box: this.box.uuid,
                context: ctx
            } 
            this.$store.dispatch("SegmentStore/addFilter", filter_row);
        }
    },
    components: {
        FilterRow
    }
};
</script>
