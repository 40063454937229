import Vue from "vue/dist/vue.esm";
import SegmentForm from "./vue/segment-form/main";
import store from "../vuex";

export default function() {
  if (document.querySelector("segment-form")) {
    return new Vue({
      el: "segment-form",
      components: { SegmentForm },
      store
    });
  }
}
