import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";


const CustomerStore = {
  namespaced: true,
  state: {
    customer: {
      id: null,
      name: "",
      email: "",
      phone: "",
      contact_person: "",
      vat_number: "",
      account_type: null,
      active: true,
      authorizations_attributes: [],
      users_attributes: [],
      authorizations: [],
      users: []
    },
    account: null,
    errors: [],
    isActiveModal: false,
    account_types: ["gold", "silver", "bronze"],
  },
  mutations: {
    SET_CUSTOMER(state, data) {
      state.customer = data;
    },
    SET_RELATED_ACCOUNTS(state, data) {
      state.accounts = data;
    },
    SET_RELATED_AUTHORIZATIONS(state, data) {      
      data.forEach((a) => {
        let obj = {
          publisher_id: a.publisher_id, 
          list_id: a.list_id
        };
        state.customer.authorizations_attributes.push(obj)        
      })       
    },        
    SWITCH_MODAL(state) {
      state.isActiveModal = !state.isActiveModal;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
      let i = 10000;
      Object.keys(data).map((k) => {
        Snackbar.open({
          duration: i,
          type: "is-danger",
          message: `${k} ${data[k][0]}`,
          position: "is-bottom-right",
          queue: false,
        });
        i = i + 1000;
      });      
    },
    SEND_SUCCESS_MESSAGE(_state, msg, duration = 3000){
      Toast.open({
        duration: duration,
        message: msg,
        type: "is-success",
      });
    },    
  },
  actions: {
    setCurrent({ commit }, payload) {
      //commit("SET_RELATED_ACCOUNTS", payload.users);
      //commit("SET_RELATED_AUTHORIZATIONS", payload.authorizations);          
      //delete payload.users;
      //delete payload.authorizations;
      commit("SET_CUSTOMER", payload);
    },
    switchModal({ commit } ) {
      commit("SWITCH_MODAL");
    },
    async save({ commit }, payload) {
      await axios
        .post("/api/v1/customers", {customer: payload})
        .then(function(response) {
          commit("SEND_SUCCESS_MESSAGE", "Customer Saved!");
          setTimeout(() => {
            window.location.href = `/admin/customers/${response.data.id}/edit`;
          }, 500);                    
        })
        .catch(function(error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async update({ commit }, customer) {
      delete customer.users;
      delete customer.authorizations;
      await axios
        .put(`/api/v1/customers/${customer.id}`, customer)
        .then(function(response) {
          commit("SET_CUSTOMER", response.data);
          commit("SEND_SUCCESS_MESSAGE", "Customer Updated!");
        })
        .catch(function(error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async createAccount({ commit, state }, payload) {
      let apipath = `/api/v1/customers/${state.customer.id}/account`;
      await axios
        .post(apipath, { account: payload, customer: state.customer })
        .then(function(response) {
          commit("SWITCH_MODAL");
          commit("SET_CUSTOMER", response.data);          
          commit("SEND_SUCCESS_MESSAGE", "Account added!");
        })
        .catch(function(error) {
          commit("SET_ERRORS", error.response.data);          
        });
    },
  },
};

export default CustomerStore;
