import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'
const jwt = require("jsonwebtoken");

const ReportStore = {
  namespaced: true,
  state: {
    choosed: {
      period: null,
      campaigns: null,
      advertisers: null,
      categories: null,
      databases: null,      
    },
    isLoading: false,
    data: []
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.data = data;
      state.isLoading = false
    },
    CLEAN_FORM(state, _data) {
      state.choosed = {
        period: null,
        campaigns: null,
        advertisers: null,
        categories: null,
        databases: null,
      }
      state.data = []      
      state.isLoading = false
    },    
    ACTIVATE_LOADING(state, _data){
      state.isLoading = true    
    }
  },
  getters: {
    isDownlodable: (state) => {
      return state.data.length >= 1;
    },
    csvReport: (state) => {
      console.log(state)
      let csvContent = [
        [
          "CAMPAIGN_ID",
          "CAMPAIGN",
          "SUBJECT",
          "CLICK",
          "UCLICK",
          "SENT",
          "OPEN",
          "UOPEN",
          "OR",
          "CTR",
          "COP"
        ],
        ...state.data.map((row) => [
          `"${row.campaign_id}"`,
          `"${row.campaign_name}"`,
          `"${row.subject}"`,
          `"${row.click}"`,
          `"${row.uclick}"`,
          `"${row.sent}"`,
          `"${row.open}"`,
          `"${row.uopens}"`,
          `"${row.or}%"`,
          `"${row.ctr}%"`,
          `"${row.cop}%"`
        ])
      ].map(e => e.join(",")).join("\n");          

      return encodeURI("data:text/csv;charset=utf-8," + csvContent);
    }
  },
  actions: {
    async updateReportData({ commit }, report) {      
      commit("ACTIVATE_LOADING");
      let jwt_params = jwt.sign({ report: report }, "target.key")    
      const { data } = await axios.get('/api/v1/reports', { params: { report: jwt_params } });
      commit("SET_REPORT_DATA", data);
    },
    cleanForm({ commit }) {
      commit("CLEAN_FORM");
    },
  }
}

export default ReportStore;
