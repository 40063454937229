import Vue from 'vue/dist/vue.esm'
import StatsBox from './vue/segment-form/stats-box'
import store from '../vuex';


export default function() {
  if (document.querySelector('stats-box')) {
    return new Vue({
      el: 'stats-box',
      components: { StatsBox },
      store
    })
  }
}