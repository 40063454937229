<template>
    <div>
        <b-field>
            <b-field class="file" v-if="!disabled">
                <b-upload v-model="file">
                    <a
                        class="button is-primary has-badge-rounded has-badge-left"
                        :data-badge="choosed.length"
                    >
                        <b-icon icon="upload"></b-icon>
                        <span>Click to upload</span>
                    </a>
                </b-upload>
                <span class="file-name" v-if="file">{{ file.name }}</span>
            </b-field>
        </b-field>
        <b-field label="zip codes">
            <b-taginput
                :disabled="disabled"
                v-model="choosed"
                ellipsis
                placeholder="Add zip code">
            </b-taginput>
        </b-field>        
    </div>
</template>

<script>
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false            
        }
    },
    data() {
        return {
            file: null,
            choosed: []
        };
    },
    computed: {
        payload() {
            let val =
                this.choosed == null || this.choosed.length == 0
                    ? null
                    : this.choosed;
            return {
                reference: this.filter,
                value: val
            };
        }
    },
    watch: {
        file() {
            let mime_types = ["text/csv", "text/plain"];
            let extensions = ["csv", "txt"];

            if (this.file && !mime_types.includes(this.file.type)) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: "file must be txt or csv!",
                    position: "is-bottom",
                    type: "is-danger"
                });
                this.file = null;
            }

            if (this.file !== null) {
                let reader = new FileReader();
                let regex = /^([0-9]+(?:,[0-9]+)*)/g;
                reader.readAsText(this.file, "UTF-8");
                reader.onload = evt => {
                    let matches = evt.target.result.match(regex);
                    if(matches !== null && matches.length > 0){
                        this.choosed = evt.target.result.split(",");                    
                    }
                    else{
                        this.file = null
                        alert('error on csv structure')
                    }
                    
                };
                reader.onerror = evt => {
                    reader.abort();
                    console.error("error csv file loading", evt);
                };
            }
        },
        payload() {
            this.$store.dispatch(
                "SegmentStore/updateFilterValue",
                this.payload
            );
        }
    },
    mounted(){
        if(this.filter.value !== null){
            this.choosed = this.filter.value
        } 
    }
};
</script>
