import { v1 as uuidv1 } from "uuid";

import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import Qs from "qs";
import { ToastProgrammatic as Toast } from "buefy";
const jwt = require("jsonwebtoken");

axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };
  return config;
});

const SegmentStore = {
  namespaced: true,
  state: {
    segment: {
      id: null,
      name: "",
      publisher_ids: [],
      dbs: [],
      audience_boxes: [],
    },
    statistics: 0,
    isLoading: false
  },

  getters: {
    query_params: (state) => {
      let jwt_param =
        state.segment.audience_boxes.length > 0
          ? jwt.sign({ boxes: state.segment.audience_boxes }, "target.key")
          : [];

      return {
        target: {
          publisher_ids: state.segment.publisher_ids,
          dbs: state.segment.dbs,
          audience_boxes: jwt_param,
        },
      };
    },
  },

  mutations: {
    SET_CURRENT(state, data) {
      state.segment = data;
    },
    SET_STATISTICS(state, stats) {
      state.statistics = stats;
    },
    ADD_AUDIENCE_BOX(state, box) {
      state.segment.audience_boxes.push(box);
    },
    REMOVE_AUDIENCE_BOX(state, box) {
      const idx = state.segment.audience_boxes.map((i) => i.uuid).indexOf(box);
      state.segment.audience_boxes.splice(idx, 1);
    },
    ADD_FILTER_ROW(state, filter) {
      let idx = state.segment.audience_boxes
        .map((i) => i.uuid)
        .indexOf(filter.box);
      state.segment.audience_boxes[idx].filters.push(filter);
    },
    UPD_FILTER_ROW_TYPE(state, payload) {
      let box_idx = state.segment.audience_boxes
        .map((i) => i.uuid)
        .indexOf(payload.reference.box);
      let filter_idx = state.segment.audience_boxes[box_idx].filters
        .map((i) => i.uuid)
        .indexOf(payload.reference.uuid);
      state.segment.audience_boxes[box_idx].filters[filter_idx].type =
        payload.value;
      state.segment.audience_boxes[box_idx].filters[filter_idx].value = null;
    },
    UPD_FILTER_ROW_VALUE(state, payload) {
      let box_idx = state.segment.audience_boxes
        .map((i) => i.uuid)
        .indexOf(payload.reference.box);
      let filter_idx = state.segment.audience_boxes[box_idx].filters
        .map((i) => i.uuid)
        .indexOf(payload.reference.uuid);
      state.segment.audience_boxes[box_idx].filters[filter_idx].value =
        payload.value;
    },
    REMOVE_FILTER_ROW(state, filter) {
      let box_idx = state.segment.audience_boxes
        .map((i) => i.uuid)
        .indexOf(filter.box);
      let filter_idx = state.segment.audience_boxes[box_idx].filters
        .map((i) => i.uuid)
        .indexOf(filter.uuid);
      state.segment.audience_boxes[box_idx].filters.splice(filter_idx, 1);
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },    
  },

  actions: {
    setCurrent({ commit }, payload) {
      commit("SET_CURRENT", payload);
    },
    addAudience({ commit }) {
      let box = { uuid: uuidv1(), filters: [] };
      commit("ADD_AUDIENCE_BOX", box);
    },
    removeAudience({ commit }, box) {
      commit("REMOVE_AUDIENCE_BOX", box);
    },
    addFilter({ commit }, filter) {
      filter.uuid = uuidv1();
      filter.type = null;
      filter.value = null;
      commit("ADD_FILTER_ROW", filter);
    },
    updateFilterType({ commit }, payload) {
      commit("UPD_FILTER_ROW_TYPE", payload);
    },
    updateFilterValue({ commit }, payload) {
      commit("UPD_FILTER_ROW_VALUE", payload);
    },
    removeFilter({ commit }, filter) {
      commit("REMOVE_FILTER_ROW", filter);
    },
    setLoading({ commit }, value) {
      commit("SET_LOADING", value);
    },
    async save({ getters }, name) {
      let params = getters.query_params;
      params.target.name = name;

      await axios
        .post("/api/v1/segments", params)
        .then(function (response) {
          window.location.href = `/admin/segments/${response.data.id}/edit`;
        })
        .catch(function (_) {
          Toast.open({
            message: "Error!",
            type: "is-danger",
          });
        });
    },
    async clone(_, data) {
      await axios
        .post(`/api/v1/segments/${data.id}/duplicate`, { name: data.name })
        .then(function (_) {
          Toast.open({
            message: "Segment duplicated",
            type: "is-success",
          });
          location.reload();
        })
        .catch(function (_) {
          Toast.open({
            message: "Duplication Error!",
            type: "is-danger",
          });
        });
    },
    async archive(_, data) {
      await axios
        .put(`/api/v1/segments/${data.id}/archive`)
        .then(function (_) {
          Toast.open({
            message: "Segment Archived",
            type: "is-success",
          });
          location.reload();
        })
        .catch(function (_) {
          Toast.open({
            message: "Archive Error!",
            type: "is-danger",
          });
        });
    },
    async update({ getters }, segment) {
      delete segment.target_stats;
      let params = getters.query_params;
      params.target.name = segment.name;
      await axios
        .put(`/api/v1/segments/${segment.id}`, params)
        .then(function (_) {
          Toast.open({
            message: "Segment Updated!",
            type: "is-success",
          });
        })
        .catch(function (error) {
          Toast.open({
            message: "Error!",
            type: "is-danger",
          });
        });
    },
    async getStats({ commit, getters }) {
      const { data } = await axios({
        method: "get",
        url: "/api/v1/segments/search",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: getters.query_params,
      });      
      commit("SET_STATISTICS", data);
      commit("SET_LOADING", false);
    }    
  },
};

export default SegmentStore;
