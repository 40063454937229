<template>
    <b-field>
        <b-taginput
            v-model="domains"
            icon="globe-europe"
            type="is-primary"
            placeholder="Add domains"
            :disabled="disabled"            
        ></b-taginput>
    </b-field>
</template>

<script>
import { mapState } from "vuex";
const rexp = new RegExp(
    /^([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
    "i"
);
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            available_types: ['email_not_in', 'email_in'],
            domains: []
        };
    },
    computed: {
        antagonist_name(){
            return this.available_types.find(i => i !== this.filter.type);
        },
        used_domains() {
            let antagonist = this.$store.state.SegmentStore.segment.audience_boxes
                    .find(i => i.uuid === this.filter.box)
                    .filters
                    .find(i => i.type === this.antagonist_name);
            return (antagonist !== undefined)
                ? antagonist.value
                : [];
        },        
        payload() {
            let domains = this.domains.length > 0 ? this.domains : null;
            return {
                reference: this.filter,
                value: domains
            };
        }
    },
    watch: {
        domains() {
            this.$store.dispatch(
                "SegmentStore/updateFilterValue",
                this.payload
            );
        }
    },
    methods: {
        validateDomain(domain) {
            return rexp.test(domain) && !this.used_domains.includes(domain)
        }
    },
    mounted(){
        if(this.filter.value !== null){
            this.domains = this.filter.value
        }
    }
};
</script>