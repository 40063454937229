export default {
  methods: {
    hasError(key) {
      if (
        Object.keys(this.errors).length > 0 &&
        this.errors.constructor === Object
      ) {
        return this.errors.hasOwnProperty(key);
      }
      return false;
    },
  },
  removeError(key) {
    if (
      Object.keys(this.errors).length > 0 &&
      this.errors.constructor === Object
    ) {
      if (this.errors.hasOwnProperty(key)) {
        delete this.errors[key];
      }
    }
  },
};
