<template>
  <button @click="downloadCSV()" v-if="isDownlodable == true" class="button is-primary is-small">
    <span class="icon">
      <i class="fas fa-file-csv"></i>
    </span>
    <span>Download CSV</span>
  </button>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import moment from "moment";
export default Vue.component("report-downloader", {
  computed: {
    isDownlodable(){
      return this.$store.getters["ReportStore/isDownlodable"];
    },
    download_link(){
      return this.$store.getters["ReportStore/csvReport"];
    } 
  },
  methods: {
    downloadCSV(){
      let filename = moment(new Date()).format("DDMMYYYYHHmm");
      let link = document.createElement("a");
      link.setAttribute("href", this.download_link);
      link.setAttribute("download", `report-${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  }
});
</script>