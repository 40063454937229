import Vue from "vue/dist/vue.esm";
import ProfileForm from "./vue/profile-form/main";
import store from "../vuex";

export default function() {
  if (document.querySelector("profile-form")) {
    return new Vue({
      el: "profile-form",
      components: { ProfileForm },
      store
    });
  }
}
