<template>
    <div class="buttons">
        <b-button
            icon-left="thumbs-up"
            size="is-small"
            @click="approveCampaign()"
            type="is-success"
            >APPROVE</b-button
        >
        <b-button
            icon-left="thumbs-down"
            size="is-small"
            @click="rejectCampaign()"
            type="is-danger"
            >REJECT</b-button
        >
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            collapses: [],
        };
    },
    props: {
        "context-url": {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        ...mapState({
            campaign: (state) => state.CampaignStore.campaign,
        }),
        delivery_dates() {
            return this.$store.getters["CampaignStore/delivery_dates"];
        },
        sent_modes() {
            return this.$store.getters["CampaignStore/sent_modes"];
        },
        approval_message() {
            let role = "customer";
            let role_name = "customer";

            if (this.hasTrafficScheduling) {
                role = "traffic";
                role_name = "traffic manager";
            }

            let daylist = this.getDayList(role);

            return `Are you sure you want to <b>approve</b> this campaign?
            <br />This action cannot be undone.<br />
            The campaign has been set up by <u>${role_name}</u> with sent mode 
            <strong>${this.sent_modes[role]}</strong> 
            and and the delivery is scheduled for: ${daylist}`;
        },
        hasTrafficScheduling(){
            return this.sent_modes.traffic  != null 
                    && this.delivery_dates.traffic != null
                    && this.delivery_dates.traffic.length > 0;
        },
        payload() {
            let camp = this.campaign;
            delete camp.customer;
            delete camp.advertiser;
            delete camp.segment;
            delete camp.category;            

            if(camp.scheduling_traffic.length == 0 && camp.sent_mode_traffic == null){
                camp.scheduling_traffic = camp.scheduling_customer;
                camp.sent_mode_traffic = camp.sent_mode_customer;
            }            

            let creativities = this.collapses.map((c) => {
                delete c.name;
                c;
            });

            return {
                ...camp,
                custom_creativities_attributes: this.collapses,
            };
        },
    },
    methods: {
        getDayList(role) {
            if (this.delivery_dates[role].length > 0) {
                let days = this.delivery_dates[role]
                    .map((d) => {
                        return `<li>- <strong>${d}</srtong></li>`;
                    })
                    .join("");
                return `<ul>${days}</ul>`;
            }
            return this.delivery_dates[role];
        },
        approveCampaign() {    
            this.$buefy.dialog.confirm({
                title: "Campaign Approval",
                message: this.approval_message,
                confirmText: "Approve",
                type: "is-success",
                onConfirm: () =>
                    this.$store.dispatch("CampaignStore/approve", {
                        payload: this.payload,
                        redirect_url: this.contextUrl,
                    }),
            });
        },
        rejectCampaign() {
            this.$store.dispatch("CampaignStore/reject", this.contextUrl);
        },
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.collapses = this.$attrs.preload;
        }
    },
};
</script>