<template>
    <table class="table is-narrow is-bordered is-striped is-fullwidth">
        <thead class="thead-dark">
            <tr>
                <th class="has-text-centered" scope="col">Rank</th>
                <th class="has-text-centered" scope="col">Type</th>
                <th scope="col">Name</th>
                <th class="has-text-centered" scope="col">MAIL</th>
                <th class="has-text-centered" scope="col">SMS</th>
                <th scope="col">Publisher name</th>
                <th scope="col">Last update</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <draggable v-model="dblist" tag="tbody">
            <tr v-for="(item, index) in dblist" :key="item.id" class="draggable">
                <td class="has-text-centered">{{ index }}</td>
                <td class="has-text-centered">{{ item.type }}</td>
                <td>{{ item.name }}</td>
                <td class="has-text-centered">{{ item.mail }}</td>
                <td class="has-text-centered">{{ item.sms }}</td>
                <td>{{ item.publisher_name }}</td>
                <td>{{ item.updated_at }}</td>
                <td>
                    <b-button @click="editDB(item.edit_link)" size="is-small">Edit</b-button>
                    <b-button
                        v-if="!item.is_used"
                        @click="deleteDB(item)"
                        type="is-danger"
                        size="is-small"
                    >Delete</b-button>
                </td>
            </tr>
        </draggable>
    </table>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default Vue.component("databases-list", {
    display: "Table",
    order: 1,
    data() {
        return {
            dblist: []
        };
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("collection"))
            this.dblist = this.$attrs.collection;
    },
    methods: {
        editDB(link) {
            window.location.href = link;
        },
        async deleteDB(db) {
            await this.$store.dispatch("DatabaseStore/delete", db);
        }
    },
    watch: {
        dblist() {
            axios
                .post("/api/v1/lists/sort", { databases: this.dblist })
                .then(function(response) {
                    //alert(1)
                    this.dblist = response.data;
                })
                .catch(function(error) {
                    //alert(2)
                });
        }
    },
    components: {
        draggable
    }
});
</script>

<style scoped>
.draggable {
    cursor: move;
}
</style>