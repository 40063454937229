<template>
    <section>
        <card-box>
            <template slot="title">Main info</template>
            <template slot="title-right">
                <b-switch v-model="advertiser.active" true-value="true" false-value="false">Active</b-switch>
            </template>            
            <div class="columns is-multiline is-vcentered">
                <div class="column is-12">
                    <b-field :type="{ 'is-danger': hasError('name') }" label="Name" label-position="on-border" expanded>
                        <b-input
                            v-model="advertiser.name"
                            placeholder="insert advertiser name"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
            </div>
        </card-box>
    </section>
</template>

<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default { 
    extends: FormComponent,    
    computed: {
        ...mapState({
            advertiser: state => state.AdvertiserStore.advertiser,
            errors: (state) => state.AdvertiserStore.errors,
        })
    },
    methods: {
        updateAdvertiser(payload) {
            this.$store.dispatch("AdvertiserStore/setCurrent", payload);
        },        
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.updateAdvertiser(this.$attrs.preload);
        }
    },
    components: {
        CardBox
    }
};
</script>