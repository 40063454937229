import Vue from "vue/dist/vue.esm";
import DatabaseForm from "./vue/database-form/main";
import store from "../vuex";

export default function() {
  if (document.querySelector("database-form")) {
    return new Vue({
      el: "database-form",
      components: { DatabaseForm },
      store
    });
  }
}
