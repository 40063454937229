import Vue from "vue/dist/vue.esm";
import DatabasesList from './vue/databases-list'
import store from "../vuex";

export default function() {
  if (document.querySelector("databases-list")) {
    return new Vue({
      el: "databases-list",
      components: { DatabasesList },
      store
    });
  }
}
