<template>
    <div class="buttons">
        <b-checkbox
            v-model="choosed"
            :disabled="$attrs.disabled"
            :native-value="item.id"
            class="button"
            v-bind:key="`cb_${item.id}`"
            v-for="item in items"
        >{{ item.name_en }}</b-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            choosed: []
        };
    },
    computed: {
        current_store() {
            let str = this.settings.action;
            return str.substr(0, str.indexOf("/"));
        },
        items() {
            return this.$store.state[this.current_store][this.settings.name];
        },
        payload() {
            let val =
                this.choosed == null || this.choosed.length == 0
                    ? null
                    : this.choosed;
            return {
                reference: this.filter,
                value: val
            };
        }
    },
    watch: {
        choosed() {
            this.$store.dispatch(
                "SegmentStore/updateFilterValue",
                this.payload
            );
        }
    },
    mounted() {        
        this.$store.dispatch(this.settings.action);
        if(this.filter.value !== null){
            this.choosed = this.filter.value
        }
    }
};
</script>