import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

import AnagStore from './stores/anag_store'
import AdvertiserStore from './stores/advertiser_store'
import SegmentStore from './stores/segment_store'
import FilterStore from './stores/filter_store'
import CampaignStore from './stores/campaign_store'
import CategoryStore from './stores/category_store'
import ExcludedUrlStore from './stores/excluded_url_store'
import DatabaseStore from './stores/database_store'
import CustomerStore from './stores/customer_store'
import PublisherStore from './stores/publisher_store'
import ReportStore from './stores/report_store'
import SchedulingStore from './stores/scheduling_store'
import ProfileStore from './stores/profile_store'

const store = new Vuex.Store({
  modules: {
    AdvertiserStore,
    AnagStore,
    SegmentStore,
    FilterStore,
    CampaignStore,
    DatabaseStore,
    CustomerStore,
    CategoryStore,
    ExcludedUrlStore,
    PublisherStore,
    ReportStore,
    SchedulingStore,
    ProfileStore
  }
});

export default store
