import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";


const DatabaseStore = {
  namespaced: true,
  state: {
    database: {
      name: "",
      type: null,
      publisher_id: null,
      supply_file: null,
      supplied_at: null,      
      ranking: 0,
      footer: "",
      sender_alias: "",
      active: true
    },
    errors: []
  },
  mutations: {
    SET_DATABASE(state, data) {
      state.database = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
      let i = 10000;
      Object.keys(data).map((k) => {
        Snackbar.open({
          duration: i,
          type: "is-danger",
          message: `${k} ${data[k][0]}`,
          position: "is-bottom-right",
          queue: false,
        });
        i = i + 1000;
      });      
    },    
  },

  actions: {    
    setCurrent({ commit }, payload){
      commit("SET_DATABASE", payload);
    },
    async save(_, payload) {
      await axios
      .post("/api/v1/lists", payload)
      .then(function(response) {
        Toast.open({
          message: "Database Saved!",
          type: "is-success",
        });
        window.location.href = `/admin/lists/${response.data.id}/edit`;
      })
      .catch(function(error) {
        commit("SET_ERRORS", error.response.data);
      });
    },
    async update({ commit }, database) {
      let id = database.id;
      await axios
        .put(`/api/v1/lists/${id}`, database)
        .then(function(response) {
          Toast.open({
            message: "Database Updated!",
            type: "is-success",
          });
          commit("SET_DATABASE", response.data);
        })
        .catch(function(error) {
          commit("SET_ERRORS", error.response.data);
        });
    },  
    async delete({ _ }, db) {
      await axios
        .delete(`/api/v1/lists/${db.id}`)
        .then(function(response) {
          Toast.open({
            message: "Database deleted!",
            type: "is-success",
          });
        })
        .catch(function(error) {
          commit("SET_ERRORS", error.response.data);
        });
    },        
  }
};

export default DatabaseStore;
