import Vue from 'vue/dist/vue.esm'
import CampaignToolbar from './vue/campaign-form/toolbar'
import store from '../vuex';


export default function() {
  if (document.querySelector('campaign-toolbar')) {
    return new Vue({
      el: 'campaign-toolbar',
      components: { CampaignToolbar },
      store
    })
  }
}