<template>
    <button class="button is-small is-pulled-right" @click="archive">Archive</button>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import Buefy from "buefy";
export default Vue.component("segment-archiver", {
    props: {
        segment: {
            type: String,
            default: null,
        },
    },
    methods: {
        archive() {
            this.$buefy.dialog.confirm({
                title: `Archive segment`,
                message: `Are you sure you want to <b>archive</b> 
                     this segment? 
                     This action cannot be undone.`,
                confirmText: "Archive",
                type: "is-warning",
                hasIcon: false,
                onConfirm: () => {
                    this.$store.dispatch("SegmentStore/archive", { id: this.segment });
                },
            });
        },
    },
});
</script>