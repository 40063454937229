<template lang="html">
  <transition name="fade" appear>
    <div v-show="visible" v-bind:class="classObject">
      <button @click.prevent="closeMessage" class="delete"></button>
      {{ content }}
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'

export default Vue.component('notify', {
  props: ['message', 'status'],
  data(){
    return{
      content: this.message || '',
      type: this.status || 'info',
      visible: true
    }
  },
  computed: {
    classObject(){
      return {
        notification: true,
        [`is-${this.status}`]: true
      }
    }
  },
  methods: {
    closeMessage(){
      this.visible = false
    }
  }
})
</script>

<style lang="css">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to{
  opacity: 0
}
</style>
