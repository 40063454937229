import Vue from "vue/dist/vue.esm";
import ExcludedUrlForm from "./vue/excluded-url-form/main";
import store from "../vuex";

export default function () {
  if (document.querySelector("excluded-url-form")) {
    return new Vue({
      el: "excluded-url-form",
      components: { ExcludedUrlForm },
      store
    });
  }
}
