<template>
    <card-box>
        <template slot="title">Footer</template>
        <b-tabs position="is-right" type="is-toggle">
            <b-tab-item label="Edit" icon="edit">
                <editor                    
                    v-model="database.footer"
                    api-key="no-api-key"
                    :init="editorConfig"
                />
            </b-tab-item>            
            <b-tab-item label="Preview" icon="eye">
                <iframe
                    width="100%"
                    style="min-height: 500px"
                    :srcdoc="database.footer"
                    type="text/html"
                >{{ fullCode }}</iframe>
            </b-tab-item>           
        </b-tabs>
        
    </card-box>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default {
    data() {
        return {
            file: null,
            editorData: "",
            editorConfig: {
                entity_encoding : "raw",
                height: 500,
                menubar: false,
                valid_elements: "*[*]",
                valid_children: "*[*]",
                extended_valid_elements: "style[type] head",
                convert_urls: false,
                relative_urls: false,
                plugins: [
                    "template fullpage code advlist autolink lists link image charmap print preview anchor",
                    "searchreplace fullscreen",
                    "media paste table "
                ],
                toolbar:
                    "template | undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | image | code",
                fullpage_default_doctype: "<!DOCTYPE html>",
                fullpage_default_encoding: "UTF-8",
                fullpage_hide_in_source_view: false
            },
            headCode: ""
        };
    },
    computed: {
        ...mapState({
            database: state => state.DatabaseStore.database
        }),
        fullCode() {
            return this.headCode.concat(this.editorData);
        }
    },
    watch: {
        fullCode(){
            this.database.footer = this.fullCode
        }
    },
    methods: {
        minifyHTML(code) {
            let dummyDOM = document.createElement("html");
            dummyDOM.innerHTML = code.replace(/\n/g, "");
            this.headCode = dummyDOM.querySelector("head").innerHTML;
            return dummyDOM.innerHTML;
        }
    },
    components: {
        CardBox,
        Editor
    }
};
</script>