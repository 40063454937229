<template>
    <section>
        <card-box>
            <template slot="title">Main info</template>
            <div class="columns is-multiline is-vcentered">
              <div class="column is-2">
                  <b-field :type="{ 'is-danger': hasError('code') }" label="Code" label-position="on-border" expanded>
                      <b-input
                          v-model="category.code"
                          placeholder="insert code"
                          type="text"
                          required
                      ></b-input>
                  </b-field>
              </div>
              <div class="column is-4">
                  <b-field :type="{ 'is-danger': hasError('name_it') }" label="Name IT" label-position="on-border" expanded>
                      <b-input
                          v-model="category.name_it"
                          placeholder="insert name it"
                          type="text"
                          required
                      ></b-input>
                  </b-field>
              </div>
              <div class="column is-4">
                  <b-field :type="{ 'is-danger': hasError('name_en') }" label="Name EN" label-position="on-border" expanded>
                      <b-input
                          v-model="category.name_en"
                          placeholder="insert name en"
                          type="text"
                          required
                      ></b-input>
                  </b-field>
              </div>
              <div class="column is-2">
                <b-field
                    :type="{ 'is-danger': hasError('parent_id') }"
                    label="Child Of"
                    label-position="on-border"
                    expanded
                >
                    <b-select
                    v-model="category.parent_id"
                    expanded
                    >
                    <option
                        v-for="option in categories"
                        :value="option.id"
                        :key="option.id"
                    >
                        {{ option.name_en }}
                    </option>
                    </b-select>
                </b-field>
              </div>
            </div>
        </card-box>
    </section>
</template>


<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default { 
    extends: FormComponent,
    data() {
        return {};
    },
    computed: {
        ...mapState({
            category: state => state.CategoryStore.category,
            errors: state => state.CategoryStore.errors,
            categories: (state) => {
                let cat = state.CategoryStore.category
                return state.AnagStore.categories.filter((elem) => {
                    if(elem.id != cat.id){
                        return elem
                    }                
                })                
            },
        })
    },
    methods: {
        updateCategory(payload) {
            this.$store.dispatch("CategoryStore/setCurrent", payload);
        },           
    },
    mounted() {
        this.$store.dispatch("AnagStore/listCategories");
        if (this.$attrs.hasOwnProperty("preload")) {
            this.updateCategory(this.$attrs.preload);
        }
    },
    components: {
        CardBox
    }
};
</script>