import Vue from 'vue/dist/vue.esm'
import StatsUploader from './vue/segment-form/stats-uploader'
import store from '../vuex';

export default function () {
  const cloners = document.querySelectorAll("stats-uploader")
  for (const el of cloners) {
    new Vue({
      el: el,
      components: { StatsUploader },
      store,
      render(createElement) {
        return createElement('stats-uploader', {
          props: {
            row_id: el.attributes.row_id.value,
            segment: el.attributes.segment.value
          }
        })
      }
    })
  }
}