import Vue from 'vue/dist/vue.esm'
import CampaignStopper from './vue/campaign-form/campaign-stopper'
import store from '../vuex';

export default function () {
    const cloners = document.querySelectorAll("campaign-stopper")
    for (const el of cloners) {
        new Vue({
            el: el,
            components: { CampaignStopper },
            store,
            render(createElement) {
                return createElement('campaign-stopper', {
                    props: {
                        campaign: el.attributes.campaign.value,
                        campaign_name: el.attributes.rowname.value,
                    }
                })
            }
        })
    }
}