<template>
    <b-collapse
        class="card"
        animation="slide"
        :open="isOpen"
        @open="isOpen = true"
        @close="isOpen = false"
    >
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <div class="card-header-title">Assigned DBs</div>
            <a class="card-header-icon">
                <b-icon pack="fas" :icon="props.open ? 'chevron-down' : 'chevron-left'"></b-icon>
            </a>
        </div>
        <div class="card-content is-paddingless">
            <div class="content is-paddingless">
                <card-box :key="`pub_${publisher.id}`" v-for="publisher in publishers">
                    <template slot="title">
                        publisher:&nbsp;
                        <strong>{{ publisher.name }}</strong>
                    </template>
                    <section v-if="publisher.lists.length == 0">
                        <div class="has-text-centered">
                            <p>
                                <span class="icon is-medium">
                                    <i class="fas fa-frown-open fa-2x"></i>
                                </span>
                            </p>
                            <p>Sorry no {{ title }} founded</p>
                        </div>
                    </section>
                    <div class="columns is-multiline">
                        <div
                            class="column is-3"
                            v-bind:key="`item_${index}`"
                            v-for="(list, index) in publisher.lists"
                        >
                            <b-checkbox
                                v-model="ids"
                                :native-value="list.id"
                                type="is-success"
                                size="is-small"
                            >{{ list.name | uppercase }}</b-checkbox>
                        </div>
                    </div>
                </card-box>
            </div>
        </div>
    </b-collapse>
</template>

<script>
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default {
    props: {
     authorizations: {
      type: Array,
      default: []
     }
    },
    data() {
        return {
            isOpen: false,
            ids: this.authorizations.map((a) => {
                return a.list_id;
            })
        };
    },
    computed: {
        ...mapState({
            publishers: (state) => state.AnagStore.publishers,
        }),
        lists(){
            return this.publishers.map((p) => {
                return p.lists
            }).flat().map((list) => {
                let substitute = this.authorizations.filter((a) => {
                    return a.list_id === list.id
                })
                let res = {
                    list_id: list.id,
                    publisher_id: list.publisher_id
                }
                if(substitute.length == 1){
                    res.id = substitute[0].id                    
                }
                return res
            })
        },
        payload(){
            let choosed = this.lists.filter((l) => {
                return this.ids.includes(l.list_id);
            });
            
            let toremove = this.authorizations.filter((a) => {
                return !this.ids.includes(a.list_id)
            }).map((a) => {
                return {
                    id: a.id,
                    _destroy: '1'
                }                
            })            
            
            return [...choosed, ...toremove]
        }
    },
    watch: {        
        payload: {
            deep: true,
            handler(){
                this.$emit("change", this.payload);
            }
        }
    },
    mounted() {        
        this.$store.dispatch("AnagStore/listPublishers");
        // this.ids = this.authorizations.map((a) => {
        //  return a.list_id;
        // })
    },
    components: {
        CardBox,
    },
};
</script>