import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'


const SchedulingStore = {
  namespaced: true,
  state: {
    scheduling: {
      customer: [],
      traffic: [],
      minDate: new Date(),
      minDays: 2,
      maxDays: 5,
    }
  },
  mutations: {
    ADD_DAY(state, data) {
      state.scheduling.customer.push(data);
    },
    REMOVE_DAY(state, data) {
     state.scheduling.customer = state.scheduling.customer.filter((elem) => {
      return elem != data;
     })   
   },
   RESET_DAYS(state) {
    state.scheduling.customer = []
   }
  },
  actions: {
   addDay({ commit }, data){    
    commit("ADD_DAY", data)
   },
   removeDay({ commit }, data){
    commit("REMOVE_DAY", data)
   },
   resetDays({ commit }){
    commit("RESET_DAYS")
   },   
  },
};
export default SchedulingStore;
