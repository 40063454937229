import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";


const AdvertiserStore = {
  namespaced: true,
  state: {
    advertiser: {
      name: "",
      active: true
    },
    errors: []
  },
  mutations: {
    SET_ADVERTISER(state, data) {
      state.advertiser = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
      let i = 10000;
      Object.keys(data).map((k) => {
        Snackbar.open({
          duration: i,
          type: "is-danger",
          message: `${k} ${data[k][0]}`,
          position: "is-bottom-right",
          queue: false,
        });
        i = i + 1000;
      });
    },
  },

  actions: {
    setCurrent({ commit }, payload) {
      commit("SET_ADVERTISER", payload);
    },
    async save(_, payload) {
      await axios
        .post("/api/v1/advertisers", payload)
        .then(function (response) {
          Toast.open({
            message: "Advertiser Saved!",
            type: "is-success",
          });
          window.location.href = `/admin/advertisers/${response.data.id}/edit`;
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async update({ commit }, advertiser) {
      let id = advertiser.id;
      await axios
        .put(`/api/v1/advertisers/${id}`, advertiser)
        .then(function (response) {
          Toast.open({
            message: "Advertiser Updated!",
            type: "is-success",
          });
          commit("SET_ADVERTISER", response.data);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    }
  }
};

export default AdvertiserStore;
