<template>
    <div>
        <card-box>
            <template slot="title">Customer: "{{ customer.name }}"</template>
            <template slot="title-right">
                <b-switch v-model="customer.active" true-value="true" false-value="false">Active</b-switch>
            </template>
            <div class="columns is-multiline is-vcentered">
                <div class="column is-2">
                    <b-field label="Account type" label-position="on-border">
                        <b-select v-model="customer.account_type" icon="medal" expanded>
                            <option
                                v-for="type in account_types"
                                :value="type"
                                :key="type"
                            >{{ type }}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-5">
                    <b-field label="Name" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.name"
                            placeholder="insert name"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-5">
                    <b-field label="Contact person" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.contact_person"
                            placeholder="insert contact person"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Email" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.email"
                            placeholder="insert email"
                            type="mail"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Phone" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.phone"
                            placeholder="insert phone"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Vat number" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.vat_number"
                            placeholder="insert vat number"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field label="Address" label-position="on-border" expanded>
                        <b-input
                            v-model="customer.address"
                            placeholder="insert address"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
            </div>
        </card-box>
        <br />
        <authorizations v-on:change="updateAuthorizations" :authorizations="customer.authorizations"></authorizations>
        <br />
        <accounts :accounts="customer.users" v-on:change="updateAccounts"></accounts>
    </div>
</template>


<script>
import CardBox from "../elements/card-box";
import Authorizations from "./authorizations";
import Accounts from "./accounts";
import { mapState } from "vuex";
export default {    
    computed: {
        ...mapState({
            customer: (state) => state.CustomerStore.customer,
            account_types: (state) => state.CustomerStore.account_types,
        }),
    },
    components: {
        CardBox,
        Authorizations,
        Accounts,
    },
    methods: {
        updateCustomer(payload) {
            this.$store.dispatch("CustomerStore/setCurrent", payload);
        },
        updateAuthorizations(data){
            let c = this.customer
            c.authorizations_attributes = data            
        },
        updateAccounts(data){
            let c = this.customer
            c.users_attributes = data            
        }
    },
    created() {
        if (this.$attrs.hasOwnProperty("preload"))
            this.updateCustomer(this.$attrs.preload);
    },
};
</script>