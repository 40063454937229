import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import { ToastProgrammatic as Toast } from "buefy";

const ProfileStore = {
  namespaced: true,
  state: {
    profile: {
      email: "",
      password: null,
      password_confirm: null,      
    }    
  },
  mutations: {
    SET_PROFILE(state, data) {
      state.profile = data;
    }
  },
  actions: {
    setCurrent({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    async update({ commit }, customer) {
      let id = customer.id;
      delete customer.id;
      await axios
        .put(`/api/v1/customers/${id}`, customer)
        .then(function(response) {
          Toast.open({
            message: "Profile Updated!",
            type: "is-success",
          });
          commit("SET_PROFILE", response.data);
        })
        .catch(function(error) {
          Toast.open({
            message: "Save Error!",
            type: "is-danger",
          });
        });
    },
  },
};

export default ProfileStore;
