<template>
    <card-box>
        <template slot="title">Content</template>
        <b-field :type="{ 'is-danger': hasError('subject') }" label="Campaign Subject" label-position="on-border">
            <b-input
                v-model="campaign.subject"
                placeholder="insert subject"
                type="text"
                icon="quote-right"
                :expanded="true"
                :disabled="show"
            ></b-input>
        </b-field>

        <b-field expanded v-if="campaign.markup == '' &&  !show">
            <b-upload class="is-expanded" v-model="file" drag-drop>
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your html file here or click to upload</p>
                    </div>
                </section>
            </b-upload>
        </b-field>

        <button @click="cleanMarkup()" v-if="campaign.markup !== '' && !show" type="button" class="button is-danger is-pulled-right">
            <span class="icon is-small">
                <i class="fas fa-trash"></i></span><span>
                Clean HTML 
            </span>
        </button>
        
        <b-tabs type="is-toggle" v-if="campaign.markup !== ''">
            <b-tab-item label="Preview" icon="eye">
                <iframe
                    width="100%"
                    style="min-height: 500px"
                    :srcdoc="campaign.markup"
                    type="text/html"
                >{{ fullCode }}</iframe>
            </b-tab-item>
            <b-tab-item v-if="!show" label="Edit" icon="edit">
                <editor
                    v-if="editorData !== null && !show"
                    v-model="campaign.markup"
                    api-key="no-api-key"
                    :init="editorConfig"
                />
            </b-tab-item>
        </b-tabs>        
    </card-box>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import CardBox from "../elements/card-box";
import FormComponent from "../../form-component";
import { mapState } from "vuex";
export default {
    extends: FormComponent, 
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            file: null,
            editorData: "",
            editorConfig: {
                entity_encoding : "raw",
                height: 500,
                menubar: false,
                valid_elements: "*[*]",
                valid_children: "*[*]",
                extended_valid_elements: "style[type] head",
                convert_urls: false,
                relative_urls: false,
                plugins: [
                    "template fullpage code advlist autolink lists link image charmap print preview anchor",
                    "searchreplace fullscreen",
                    "media paste table ",
                ],
                toolbar:
                    "template | undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | image | code",
                fullpage_default_doctype: "<!DOCTYPE html>",
                fullpage_default_encoding: "UTF-8",
                fullpage_hide_in_source_view: false,
                paste_enable_default_filters: false
            },
            headCode: "",
        };
    },
    computed: {
        ...mapState({
            campaign: (state) => state.CampaignStore.campaign,
            errors: (state) => state.CampaignStore.errors,
        }),
        fullCode() {
            return this.headCode.concat(this.editorData);
        },
    },
    watch: {
        file() {
            if (this.file && this.file.type == "text/html") {
                const file = this.file;
                let reader = new FileReader();

                reader.onload = (evt) => {
                    const file = evt.target.result;
                    let code = this.minifyHTML(file);
                    this.editorData = code;
                };
                reader.onerror = (evt) => {
                    reader.abort();
                    alert(evt.target.error.name);
                };

                reader.readAsText(file, "UTF-8");
            }

            this.file = null;
        },
        fullCode() {
            this.campaign.markup = this.fullCode;
        },
    },
    methods: {
        htmlDecode(input){
            let e = document.createElement('textarea');
            e.innerHTML = input;
            // handle case of empty input
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        },        
        minifyHTML(code) {
            let dummyDOM = document.createElement("html");
            dummyDOM.innerHTML = code.replace(/\n/g, "");
            this.headCode = dummyDOM.querySelector("head").innerHTML;
            return this.htmlDecode(dummyDOM.innerHTML);
        },
        cleanMarkup(){
            this.file = null;
            this.editorData = "";
            this.headCode = "";

        }
    },
    components: {
        CardBox,
        Editor,
    },
};
</script>