import Vue from 'vue/dist/vue.esm'
import Notify from './vue/notify'

export default function() {
  if (document.querySelector('notify')) {
    return new Vue({
      el: 'notify',
      components: { Notify }
    })
  }
}