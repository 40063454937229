<template>
    <section>
        <card-box>
            <template slot="title">Main info</template>
            <div class="columns is-multiline is-vcentered">
              <div class="column is-12">
                  <b-field :type="{ 'is-danger': hasError('url') }" label="Url" label-position="on-border" expanded>
                      <b-input
                          v-model="excluded_url.url"
                          placeholder="insert url"
                          type="text"
                          required
                      ></b-input>
                  </b-field>
              </div>
            </div>
        </card-box>
    </section>
</template>


<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default { 
    extends: FormComponent,
    data() {
        return {};
    },
    computed: {
        ...mapState({
            excluded_url: state => state.ExcludedUrlStore.excluded_url,
            errors: state => state.ExcludedUrlStore.errors
        })
    },
    methods: {
        updateExcludedUrl(payload) {
            this.$store.dispatch("ExcludedUrlStore/setCurrent", payload);
        },           
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.updateExcludedUrl(this.$attrs.preload);
        }
    },
    components: {
        CardBox
    }
};
</script>