<template>
  <div>
    <card-box>
      <template slot="title">Settings</template>

      <div class="columns is-multiline is-vcentered">
        <div class="column is-4">
          <b-field
            :type="{ 'is-danger': hasError('name') }"
            label="Name"
            label-position="on-border"
            expanded
          >
            <b-input
              v-model="campaign.name"
              placeholder="insert campaign name"
              expanded
              type="text"
              required
              :disabled="show"
            ></b-input>
            <span class="control"></span>
          </b-field>
        </div>
        <div class="column is-4">
          <template v-if="!$parent.customerArea">
            <b-field
              :type="{ 'is-danger': hasError('customer') }"
              label="Customer"
              label-position="on-border"
              expanded
            >
              <b-select
                v-model="campaign.customer_id"
                expanded
                required
                icon="user"
                :disabled="show"
              >
                <option
                  v-for="option in $parent.customers"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
              <span class="control"></span>
            </b-field>
          </template>
        </div>
        <div class="column is-4">
          <b-field
            :type="{ 'is-danger': hasError('advertiser') }"
            label="Advertiser"
            label-position="on-border"
            expanded
          >
            <b-select
              v-model="campaign.advertiser_id"
              expanded
              required
              icon="bullhorn"
              :disabled="show"
            >
              <option
                v-for="option in $parent.advertisers"
                :value="option.id"
                :key="option.id"
              >
                {{ option.business_name }}
              </option>
            </b-select>
            <span class="control">
              <b-button @click="addAdvertiser()" label="Add" type="is-success" icon-left="plus" />
            </span>
          </b-field>
        </div>
        <div class="column is-7">
          <b-field
            :type="{ 'is-danger': hasError('sender_alias') }"
            label="Sender Alias"
            label-position="on-border"
            expanded
          >
            <b-input
              v-model="campaign.sender_alias"
              placeholder="insert sender alias"
              type="text"
              icon="user-secret"
              required
              :disabled="show"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-5">
          <div class="block">
            <b-radio
              @input="removeError('sender_alias')"
              v-model="campaign.publisher_alias"
              name="campaign[publisher_alias]"
              native-value="0"
              :disabled="show"
              >None</b-radio
            >
            <b-radio
              v-model="campaign.publisher_alias"
              name="campaign[publisher_alias]"
              native-value="1"
              :disabled="show"
              >Before</b-radio
            >
            <b-radio
              v-model="campaign.publisher_alias"
              name="campaign[publisher_alias]"
              native-value="2"
              :disabled="show"
              >After</b-radio
            >
          </div>
        </div>
        <div class="column is-4">
          <b-field
            :type="{ 'is-danger': hasError('segment') }"
            label="Segment"
            label-position="on-border"
            expanded
          >
            <b-select
              v-model="campaign.segment_id"
              placeholder="Select a segment"
              required
              expanded
              :disabled="show"
            >
              <option
                v-for="option in $parent.segments"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            :label="`Usage volume (available ${availables})`"
            label-position="on-border"
          >
            <b-input
              v-model="campaign.usage"
              :disabled="campaign.segment_id == null || show"
              placeholder="vol"
              type="number"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            :type="{ 'is-danger': hasError('segment') }"
            label="Category"
            label-position="on-border"
            expanded
          >
            <b-select
              v-model="campaign.category_id"
              expanded
              required
              :disabled="show"
            >
              <option
                v-for="option in $parent.categories"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name_en }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            :type="{ 'is-danger': hasError('model') }"
            label="Model"
            label-position="on-border"
            expanded
          >
            <b-select
              v-if="campaign.type == 'mail'"
              placeholder="Select a model"
              v-model="campaign.model"
              required
              expanded
              :disabled="show"
            >
              <option
                :key="index"
                v-for="(model, index) in models"
                :value="model"
              >
                {{ model }}
              </option>
            </b-select>

            <b-input
              v-if="campaign.type == 'sms'"
              v-model="campaign.model"
              :disabled="true"
              placeholder="SMS"
              type="text"
              :value="SMS"
            ></b-input>
          </b-field>

          <b-field
            :type="{ 'is-danger': hasError('pay') }"
            label="Pay"
            label-position="on-border"
          >
            <b-input
              v-model="campaign.pay"
              placeholder="insert pay"
              type="number"
              step="0.01"
              icon="euro-sign"
              expanded
              :disabled="show"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-8">
          <b-field
            :type="{ 'is-danger': hasError('description') }"
            label="Description"
            label-position="on-border"
          >
            <b-input
              v-model="campaign.description"
              maxlength="200"
              type="textarea"
              :disabled="show"
            ></b-input>
          </b-field>
        </div>
      </div>
    </card-box>
    <br />
    <card-box>
      <template slot="title">Scheduling Info</template>
      <b-field
        :type="{
          'is-danger': hasError('scheduling'),
          'is-danger': hasError('sent_mode_customer'),
        }"
        label="Mode"
        label-position="on-border"
        expanded
      >
        <b-select
          v-model="sent_mode"
          placeholder="choose sent mode"
          expanded
          :disabled="show"
        >
          <option value="scheduled">Date/Time scheduled</option>
          <option value="immediate">Immediate Sending</option>
          <option value="split scheduled" disabled>
            Split multiple days with percent
          </option>
        </b-select>
      </b-field>

      <b-field
        v-if="sent_mode == 'scheduled'"
        :type="{ 'is-danger': hasError('scheduling') }"
        label="Select datetime"
        label-position="on-border"
      >
        <b-datetimepicker
          v-model="choosed_date"
          :min-datetime="minDate"
          placeholder="Click to select..."
          :disabled="show"
          expanded
          mobile-native
        ></b-datetimepicker>
      </b-field>

      <div v-if="sent_mode == 'split scheduled'">
        <!--<split-scheduling v-on:change="updateScheduling($event)"></split-scheduling>-->
        <!-- <div v-bind:key="`split_${index}`" class="column is-6" v-for="(split, index) in split_dates">1</div> -->
      </div>
    </card-box>
  </div>
</template>

<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import SplitScheduling from "./split-scheduling";
import { mapState } from "vuex";
import moment from 'moment-timezone';

export default {
  extends: FormComponent,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      models: ["CPM", "CPC", "CPL", "CPA"],
      minDate: new Date(),
    };
  },
  computed: {
    ...mapState({
      campaign: (state) => state.CampaignStore.campaign,
      availables: (state) => state.CampaignStore.segment_stats,
      errors: (state) => state.CampaignStore.errors,
    }),
    sent_mode: {
      get() {
        return this.campaign.sent_mode_customer;
      },
      set(val) {
        if (val == "immediate") {          
          this.choosed_date = moment(new Date()).tz('Europe/Amsterdam', true).format()
        }
        this.campaign.sent_mode_customer = val;
      },
    },
    choosed_date: {
      get() {
        if (
          Array.isArray(this.campaign.scheduling_customer) &&
          this.campaign.scheduling_customer.length != 0
        ) {
          return new Date(this.campaign.scheduling_customer[0].date);
        }
      },
      set(val) {
        this.campaign.scheduling_customer = [
          {
            percent: 100,
            date: val,
          },
        ];
      },
    },
  },
  beforeUpdate() {
    if (this.campaign.segment_id != null) {
      this.updateAvailables(this.campaign);
    }
  },
  methods: {
    updateAvailables(campaign) {
      this.$store.dispatch("CampaignStore/updateSegmentStats", campaign);
    },
    updateScheduling(scheduling) {
      this.campaign.scheduling_customer = scheduling;
    },
    addAdvertiser(){
        this.$buefy.dialog.prompt({
            message: `Add Advertiser:`,
            inputAttrs: {
                placeholder: "advertiser name",
                maxlength: 50
            },
            trapFocus: true,
            onConfirm: value => {                
                this.$store.dispatch("CampaignStore/addAdvertiserInline", {
                    name: value
                });
                this.$emit("advertiser-added");
            }
        });
    }
  },
  components: {
    CardBox,
    SplitScheduling,
  },
};
</script>