<template>
    <div>
        <card-box>
            <template slot="title">Profile settings</template>
            <div class="columns is-multiline is-vcentered">
                <div class="column is-6">
                    <b-field label="Firstname" label-position="on-border" expanded>
                        <b-input
                            :value="profile.firstname"
                            placeholder="insert firstname"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Lastname" label-position="on-border" expanded>
                        <b-input
                            :value="profile.lastname"
                            placeholder="insert firstname"
                            type="text"
                            required
                        ></b-input>
                    </b-field>                    
                </div>
                <div class="column is-6">
                    <b-field label="Password" label-position="on-border" expanded>
                        <b-input                            
                            placeholder="insert password"
                            type="password"
                            required
                            password-reveal
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Password confirm" label-position="on-border" expanded>
                        <b-input                            
                            placeholder="confirm password"
                            type="password"
                            required
                            password-reveal
                        ></b-input>
                    </b-field>                    
                </div>
            </div>
        </card-box>
    </div>
</template>

<script>
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default {
    props: {
        preload: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapState({
            profile: state => state.ProfileStore.profile
        })
    },
    components: {
        CardBox
    },
    methods: {
        updateProfile(payload) {
            this.$store.dispatch("ProfileStore/setCurrent", payload);
        }
    },
    mounted() {
        this.updateProfile(this.preload);
    }
};
</script>