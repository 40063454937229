import Vue from 'vue/dist/vue.esm'
import SegmentSaver from './vue/segment-form/segment-saver'
import store from '../vuex';


export default function() {
  if (document.querySelector('segment-saver')) {
    return new Vue({
      el: 'segment-saver',
      components: { SegmentSaver },
      store
    })
  }
}