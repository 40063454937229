import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";

const CampaignStore = {
  namespaced: true,
  state: {
    contextUrl: "",
    advertisers: [],
    customers: [],
    campaigns: [],
    segments: [],
    campaign: {
      type: null,
      name: "",
      customer_id: null,
      advertiser_id: null,
      category_id: null,
      segment_id: null,
      segment_from: 0,
      usage: 0,
      sender_alias: "",
      publisher_alias: 0,
      sent_mode_customer: null,
      sent_mode_traffic: null,
      scheduling_customer: [],
      scheduling_traffic: [],
      pay: 0,
      subject: "",
      markup: "",
      state: "draft",
    },
    errors: [],
    sent_mode_panel_open: false,
    segment_stats: 0,
    isLoading: false,
    approval_message: "",
  },
  mutations: {
    SET_CONTEXT_URL(state, data) {
      state.contextUrl = data;
    },
    SET_CAMPAIGN(state, data) {
      state.campaign = data;
    },
    SET_SEGMENT_STATS(state, data) {
      state.segment_stats = data;
    },
    SET_ADVERTISERS(state, data) {
      state.advertisers = data;
    },
    SET_CUSTOMERS(state, data) {
      state.customers = data;
    },
    SET_SEGMENTS(state, data) {
      state.segments = data;
    },
    SET_CAMPAIGNS(state, data) {
      state.campaigns = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
      let i = 10000;
      Object.keys(data).map((k) => {
        Snackbar.open({
          duration: i,
          type: "is-danger",
          message: `${k} ${data[k][0]}`,
          position: "is-bottom-right",
          queue: false,
        });
        i = i + 1000;
      });
    },
    SEND_SUCCESS_MESSAGE(_state, msg, duration = 3000) {
      Toast.open({
        duration: duration,
        message: msg,
        type: "is-success",
      });
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },
  },

  getters: {
    campaign_object: (state) => {
      let campaign = state.campaign;
      delete campaign.active;
      delete campaign.deleted;
      delete campaign.deleted_at;
      delete campaign.created_at;
      delete campaign.updated_at;
      return campaign;
    },
    traffic_campaign_object: (state) => {
      let campaign = state.campaign;

      return {
        sent_mode_traffic: campaign.sent_mode_traffic,
        scheduling_traffic: campaign.scheduling_traffic,
        custom_creativities_attributes: campaign.custom_creativities_attributes
      };
    },
    sent_modes: (state) => {
      return {
        customer: state.campaign.sent_mode_customer,
        traffic: state.campaign.sent_mode_traffic,
      }
    },
    delivery_dates: (state) => {
      let customer_dates = null;
      let traffic_dates = null;
      if (state.campaign.scheduling_customer.length > 0) {
        customer_dates = state.campaign.scheduling_customer.map((d) => {
          if (d != null && d.date != "") {
            return new Date(d.date).toLocaleString('it-IT');
          }
        });
      }
      if (state.campaign.scheduling_traffic.length > 0) {
        traffic_dates = state.campaign.scheduling_traffic.map((d) => {
          if (d != null && d.date != "") {
            return new Date(d.date).toLocaleString('it-IT');
          }
        });
      }

      return {
        customer: customer_dates,
        traffic: traffic_dates,
      };
    },
  },

  actions: {
    setContextUrl({ commit }, url) {
      commit("SET_CONTEXT_URL", url);
    },
    async listAdvertisers({ commit }) {
      const { data } = await axios.get("/api/v1/advertisers");
      commit("SET_ADVERTISERS", data);
    },
    async listCustomers({ commit }) {
      const { data } = await axios.get("/api/v1/customers");
      commit("SET_CUSTOMERS", data);
    },
    async listSegments({ commit }) {
      const { data } = await axios.get("/api/v1/segments");
      commit("SET_SEGMENTS", data);
    },
    async listCampaigns({ commit }) {
      const { data } = await axios.get("/api/v1/campaigns");
      commit("SET_CAMPAIGNS", data);
    },
    async listSentsCampaigns({ commit }) {
      const { data } = await axios.get("/api/v1/campaigns/sents");
      commit("SET_CAMPAIGNS", data);
    },
    async addAdvertiserInline(_, payload) {
      await axios
        .post("/api/v1/advertisers", payload)
        .then(function (_response) {
          Toast.open({
            message: "Advertiser Saved!",
            type: "is-success",
          });
        })
        .catch(function (_error) {
          Snackbar.open({
            duration: 10000,
            type: "is-danger",
            message: "Advertiser creation failed!",
            position: "is-bottom-right",
            queue: false,
          });
        });
    },
    updateCampaign({ commit }, payload) {
      commit("SET_CAMPAIGN", payload);
    },
    setLoading({ commit }, value) {
      commit("SET_LOADING", value);
    },
    async updateSegmentStats({ commit }, campaign) {
      let segment_id = campaign.segment_id
      let type = campaign.type
      const { data } = await axios.get(`/api/v1/segments/${segment_id}/stats`, { params: { type: type } });
      commit("SET_SEGMENT_STATS", data);
    },
    async save({ state, commit }) {
      await axios
        .post("/api/v1/campaigns", state.campaign)
        .then(function (response) {
          commit("SET_CAMPAIGN", response.data);
          commit("SET_ERRORS", []);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error);
        });
    },
    async update({ state, commit }) {
      await axios
        .put(`/api/v1/campaigns/${state.campaign.id}`, state.campaign)
        .then(function (response) {
          commit("SET_CAMPAIGN", response.data);
          commit("SEND_SUCCESS_MESSAGE", "Draft Saved!")
          commit("SET_ERRORS", []);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async sendApprovalRequest({ state, commit, getters }, index_url) {
      await axios
        .put(
          `/api/v1/campaigns/${state.campaign.id}/send_approval_request`,
          getters.traffic_campaign_object
        )
        .then(function (response) {
          let refid = response.data.id;
          commit("SEND_SUCCESS_MESSAGE", "Campaign scheduled")
          setTimeout(() => {
            window.location.href = `${index_url}/${refid}`;
          }, 1000);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async reject({ state, commit }, index_url) {
      await axios
        .put(`/api/v1/campaigns/${state.campaign.id}/reject`)
        .then(function (response) {
          let refid = response.data.id;
          commit("SEND_SUCCESS_MESSAGE", "Campaign rejected")
          setTimeout(() => {
            window.location.href = `${index_url}/${refid}`;
          }, 1000);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async send_test({ commit }, data) {
      await axios
        .put(`/api/v1/campaigns/${data.campaign.id}/sending_test`, {
          mail: data.mail,
        })
        .then(function (response) {
          commit("SET_LOADING", false);
          commit("SEND_SUCCESS_MESSAGE", "Test Campaign Sent!", 4000)
        })
        .catch(function (error) {
          commit("SET_LOADING", false);
          commit("SET_ERRORS", { send_test: ["failed"] })
        });
    },
    async performTrafficSave({ commit }, data) {
      await axios
        .put(`/api/v1/campaigns/${data.id}/perform_traffic_changes`, {
          campaign: data,
        })
        .then(function (_response) {
          commit("SEND_SUCCESS_MESSAGE", "Traffic changes saved!")
          setTimeout(() => {
            location.reload();
          }, 1000);
        })
        .catch(function (error) {
          //commit("SET_ERRORS", error.response.data.error)
          Toast.open({
            message: error.response.data,
            type: "is-danger",
          });
        });
    },
    async approve({ commit }, data) {
      await axios
        .put(`/api/v1/campaigns/${data.payload.id}/approve`, {
          campaign: data.payload,
        })
        .then(function (_response) {
          commit("SEND_SUCCESS_MESSAGE", "Campaign approved!")
          setTimeout(() => {
            window.location.href = data.redirect_url;
          }, 1000);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async clone(_, data) {
      await axios
        .post(`/api/v1/campaigns/${data.id}/duplicate`, { name: data.name })
        .then(function (_) {
          Toast.open({
            message: "Campaign duplicated",
            type: "is-success",
          });
          location.reload();
        })
        .catch(function (_) {
          Toast.open({
            message: "Duplication Error!",
            type: "is-danger",
          });
        });
    },
    async stopCampaign(_, data) {
      await axios
        .post(`/api/v1/campaigns/${data.id}/stop`)
        .then(function (_) {
          Toast.open({
            message: "Campaign stopped!",
            type: "is-success",
          });
          location.reload();
        })
        .catch(function (_) {
          Toast.open({
            message: "Stop Error!",
            type: "is-danger",
          });
        });
    }
  },
};

export default CampaignStore;
