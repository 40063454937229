<template>
    <b-modal
        :active.sync="$attrs.active"
        has-modal-card
        trap-focus
        aria-role="dialog"
        :can-cancel="false"
        v-on:close="cleanData"
        aria-modal
    >
        <card-box :modal="true" style="width: auto;min-width: 700px;">
            <template slot="title">{{ title | uppercase }}</template>
            <template slot="search-field">
                <b-field class="is-marginless-bottom">
                    <b-input
                        placeholder="search"
                        v-model="search_term"
                        type="text"
                        size="is-small"
                        icon="search"
                        icon-right="times-circle"
                        icon-right-clickable
                        @icon-right-click="search_term = ''"
                        rounded
                    ></b-input>
                </b-field>
            </template>
            <template slot="title-right" v-if="search_term == ''">
                <label class="b-checkbox checkbox is-small">
                    <input
                        type="checkbox"
                        v-model="choose_all_state"
                        :true-value="true"
                        value="false"
                        @change="selectAll()"
                    />
                    <span class="check is-success"></span>
                    <span v-if="choose_all_state" class="control-label">UNSELECT ALL</span>
                    <span v-if="!choose_all_state" class="control-label">SELECT ALL</span>
                </label>
            </template>

            <card-box :key="region.id" v-for="region in regions">
                <template slot="title">{{ region.name_en }}</template>
                <section v-if="filterByRegion(region).length == 0">
                <div class="has-text-centered">
                    <p>
                        <span class="icon is-medium">
                            <i class="fas fa-frown-open fa-2x"></i>
                        </span>
                    </p>
                    <p>Sorry no {{ title }} founded</p>
                </div>
                </section>                                
                <div class="columns is-multiline">
                    <div
                        class="column is-3"
                        v-bind:key="`item_${index}`"
                        v-for="(item, index) in filterByRegion(region)"
                    >
                        <b-checkbox
                            v-model="choosed"
                            :native-value="item[field_value]"
                            type="is-success"
                            size="is-small"
                        >{{ item[field_label] | uppercase }}</b-checkbox>
                    </div>
                </div>
            </card-box>
            <template slot="footer-right">
                <b-button
                    type="is-primary"
                    size="is-small"
                    icon-left="check"
                    @click="cleanData"
                >DONE</b-button>
            </template>
        </card-box>
    </b-modal>
</template>

<script>
import CardBox from "../../../elements/card-box";
export default {
    props: {
        value: {
            type: Array,
            required: false,
            default: []
        },
        regions: {
            type: Array,
            required: false,
            default: []
        },
        bsize: {
            type: String,
            required: false,
            default: "is-small"
        },
        btype: {
            type: String,
            required: false,
            default: "is-primary"
        },
        title: {
            type: String,
            required: false,
            default: "Collection"
        },
        collection: {
            type: Array,
            required: false,
            default: []
        },
        cname: {
            type: String,
            required: false,
            default: "data"
        },
        field_label: {
            type: String,
            required: false,
            default: "name_en"
        },
        field_value: {
            type: String,
            required: false,
            default: "id"
        },
        field_search: {
            type: String,
            required: false,
            default: "name_en"
        }
    },
    data() {
        return {
            search_term: "",
            choosed: [],
            choose_all: false
        };
    },
    computed: {
        all_ids() {
            return this.collection.map(i => i[this.field_value]);
        },
        filteredCollection() {
            let term = this.search_term;
            if (term == null || term == "") return this.collection;

            return this.collection.filter(item =>
                item[this.field_search]
                    .toLowerCase()
                    .includes(term.toLowerCase())
            );
        },
        choose_all_state: {
            get() {
                return this.choosed.length == this.filteredCollection.length;
            },
            set(val) {
                this.choose_all = val;
            }
        },
        isCheckboxActive(){
          return region => {
           let ids = this.ids_by_region(region);
           return this.choosed.filter(i => ids.includes(i)).length === ids.length
          }                 
        },        
    },
    watch: {
        choosed() {
            let list =
                this.choosed == null || this.choosed.length == 0
                    ? null
                    : this.choosed;

            this.$emit("change", { collection: this.cname, value: list });
            this.$emit("input", this.choosed);
        }
    },
    methods: {
        selectAll() {
            this.choose_all
                ? (this.choosed = this.all_ids)
                : (this.choosed = []);
        },
        ids_by_region(region) {
            return this.collection
                .filter(i => i.region_id === region)
                .map(i => i.id);
        },
        selectByRegion(region) {
            let ids = this.ids_by_region(region);
            let includeds = this.choosed.filter(i => ids.includes(i));
            this.choosed =
                includeds.length > 0
                    ? this.choosed.filter(i => !ids.includes(i))
                    : this.choosed.concat(ids);
        },
        filterByRegion(region){
            return this.filteredCollection.filter(i => i.region_id === region.id)
        },
        cleanData() {
            this.search_term = "";
            this.choose_all = false;
            this.$emit("close");
        }
    },
    mounted() {
        this.choosed = this.value !== null ? this.value : [];
    },
    components: {
        CardBox
    }
};
</script>