import Vue from 'vue/dist/vue.esm'
import Tooltip from './vue/elements/tooltip'

export default function() {
  const tooltips = document.querySelectorAll(".btooltip")
  for (const el of tooltips) {
    new Vue({
      el: el,
      components: { Tooltip },
      render(createElement) {
        return createElement('tooltip', {
          props: {
            label: el.attributes.label.value,
            text: el.innerText
          }
        })
      }
    })
  }
}