<template>
    <div>
        <publishers-box :account="account" :disabled="disabled"></publishers-box>
        <br />
        <template v-for="(box, index) in segment.audience_boxes">
            <div
                v-if="index > 0"
                v-bind:key="`divider_${box.uuid}`"
                class="is-divider"
                data-content="OR"
            ></div>
            <filter-box
                :box="box"
                :disabled="disabled"
                v-bind:key="`filterbox_${box.uuid}`"
                v-on:delete-box="removeAudienceBox(box.uuid)"
            ></filter-box>
        </template>

        <br />
        <button class="button" @click="addAudienceBox()" v-if="!disabled">
            <span class="icon is-small">
                <i class="fas fa-plus"></i>
            </span>
            <span>ADD AUDIENCE</span>
        </button>
    </div>
</template>


<script>
import Vue from "vue/dist/vue.esm";
import FormComponent from "../../form-component";
import { mapState } from "vuex";
import FilterBox from "./filter-box";
import PublishersBox from "./publishers-box";

export default Vue.component("segment-form", {
    extends: FormComponent,
    props: {
        current: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        account: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapState({
            segment: (state) => state.SegmentStore.segment,
        }),
    },
    mounted() {
        this.$store.dispatch("AnagStore/listPublishers");
        if (this.current !== "") {
            let parsed = JSON.parse(this.current);
            this.$store.dispatch("SegmentStore/setCurrent", parsed);
            this.$store.dispatch("SegmentStore/getStats");
        }
    },
    methods: {
        addAudienceBox() {
            this.$store.dispatch("SegmentStore/addAudience");
        },
        removeAudienceBox(box) {
            this.$store.dispatch("SegmentStore/removeAudience", box);
        },
    },
    components: {
        FilterBox,
        PublishersBox,
    },
});
</script>