<template>
<b-dropdown
    type="button"
    class="toolbar-item"
    position="is-bottom-left"
    append-to-body
    aria-role="menu"
    trap-focus
    :can-close="false"
>
    <button class="button is-dark is-small" type="button" slot="trigger">
        <b-icon icon="users"></b-icon>
        <span>AVAILABLES</span>
         <b-icon icon="caret-down"></b-icon>
    </button>

    <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
        <div class="modal-card" style="width:250px;">
            <head class="modal-card-head">TOTAL USERS IN TARGET</head>
            <section class="modal-card-body">
                <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false">
                    <section class="section">
                        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin mt-6"></b-icon>
                    </section>                    
                </b-loading>
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child has-text-centered">
                            <p class="title is-6">DEM</p>
                            <p class="subtitle is-6">{{ statistics.mail }}</p>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child has-text-centered">
                            <p class="title is-6">SMS</p>
                            <p class="subtitle is-6">{{ statistics.sms }}</p>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    </b-dropdown-item>
</b-dropdown>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
export default Vue.component("segment-form", {
    computed: {
        ...mapState({
            statistics: state => state.SegmentStore.statistics,
            isLoading: state => state.SegmentStore.isLoading
        })
    }
});
</script>