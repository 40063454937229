import Vue from 'vue/dist/vue.esm'
import SegmentArchiver from './vue/segment-form/segment-archiver'
import store from '../vuex';

export default function() {
  const cloners = document.querySelectorAll("segment-archiver")
  for (const el of cloners) {
    new Vue({
      el: el,
      components: { SegmentArchiver },
      store,
      render(createElement) {
        return createElement('segment-archiver', {
          props: {
            segment: el.attributes.segment.value
          }
        })
      }
    })
  }
}