import Vue from "vue/dist/vue.esm";
import CategoryForm from "./vue/category-form/main";
import store from "../vuex";

export default function () {
  if (document.querySelector("category-form")) {
    return new Vue({
      el: "category-form",
      components: { CategoryForm },
      store
    });
  }
}
