<template>
    <section>
        <card-box>
            <template slot="title">Main info</template>
            <template slot="title-right">
                <b-switch v-model="database.active" true-value="true" false-value="false">Active</b-switch>
            </template>            
            <div class="columns is-multiline is-vcentered">
                <div class="column is-10">
                    <b-field :type="{ 'is-danger': hasError('name') }" label="Name" label-position="on-border" expanded>
                        <b-input
                            v-model="database.name"
                            placeholder="insert database name"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="column is-2">
                    <b-field :type="{ 'is-danger': hasError('ranking') }" label="Ranking" label-position="on-border" expanded>
                        <b-input
                            v-model="database.ranking"
                            custom-class="has-text-centered"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>                
                <div class="column is-4">
                    <b-field :type="{ 'is-danger': hasError('type') }" label="Type" label-position="on-border" expanded>
                        <b-select v-model="database.type" expanded required>
                            <option
                                v-for="option in dbtypes"
                                :value="option"
                                :key="option"
                            >{{ option }}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field :type="{ 'is-danger': hasError('publisher') }" label="Publisher" label-position="on-border" expanded>
                        <b-select v-model="database.publisher_id" expanded required icon="user">
                            <option
                                v-for="option in publishers"
                                :value="option.id"
                                :key="option.id"
                            >{{ option.name }}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field :type="{ 'is-danger': hasError('sender_alias') }" label="Sender alias" label-position="on-border" expanded>
                        <b-input
                            v-model="database.sender_alias"
                            placeholder="insert alias"
                            type="text"
                            required
                        ></b-input>
                    </b-field>
                </div>
            </div>
        </card-box>
        <br />
        <footer-editor></footer-editor>
    </section>
</template>

<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import FooterEditor from "./footer-editor";
import { mapState } from "vuex";
export default { 
    extends: FormComponent,
    data() {
        return {
            dbtypes: ["B2C", "B2B"]
        };
    },
    computed: {
        ...mapState({
            publishers: state => state.AnagStore.publishers,
            database: state => state.DatabaseStore.database,
            errors: state => state.DatabaseStore.errors,
        })
    },
    methods: {
        updateDatabase(payload) {
            this.$store.dispatch("DatabaseStore/setCurrent", payload);
        },           
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.updateDatabase(this.$attrs.preload);
        }
        this.$store.dispatch("AnagStore/listPublishers");
    },
    components: {
        CardBox,
        FooterEditor
    }
};
</script>