import Vue from "vue/dist/vue.esm";
import CampaignForm from "./vue/campaign-form/main";
import store from "../vuex";

export default function() {
  if (document.querySelector("campaign-form")) {
    return new Vue({
      el: "campaign-form",
      components: { CampaignForm },
      store
    });
  }
}
