import Vue from 'vue/dist/vue.esm'
import TrafficForm from './vue/traffic-form/main'
import store from '../vuex';


export default function() {
  if (document.querySelector('traffic-form')) {
    return new Vue({
      el: 'traffic-form',
      components: { TrafficForm },
      store
    })
  }
}