import "../src/stylesheet";
import "@fortawesome/fontawesome-free/css/all.css";
import "./custom-ujs";
require.context("../src/images", true);

// Components Initializers
import Init from "../src/components/init";
import Notify from "../src/components/notify";
import Tooltip from "../src/components/tooltip";
import { DialogProgrammatic as Dialog } from "buefy";
// Advertisers
import AdvertiserForm from "../src/components/advertiser-form";
import AdvertiserSaver from "../src/components/advertiser-saver";
// Customers
import CustomerForm from "../src/components/customer-form";
import CustomerSaver from "../src/components/customer-saver";

// Categories
import CategoryForm from "../src/components/category-form";
import CategorySaver from "../src/components/category-saver";

// ExcludedUrls
import ExcludedUrlForm from "../src/components/excluded-url-form";
import ExcludedUrlSaver from "../src/components/excluded-url-saver";

// Databases
import DatabaseForm from "../src/components/database-form";
// Segments
import SegmentForm from "../src/components/segment-form";
import SegmentSaver from "../src/components/segment-saver";
import SegmentCloner from "../src/components/segment-cloner";
import SegmentArchiver from "../src/components/segment-archiver";
import SegmentStatsBox from "../src/components/stats-box";
import SegmentStatsUploader from "../src/components/segment-stats-uploader";
// Campaigns
import CampaignForm from "../src/components/campaign-form";
import CampaignCloner from "../src/components/campaign-cloner";
import CampaignStopper from "../src/components/campaign-stopper";
import CampaignToolBar from "../src/components/campaign-toolbar";
// Campaign Traffics
import TrafficForm from "../src/components/traffic-form";
import TrafficToolBar from "../src/components/traffic-toolbar";
// Publishers
import PublisherForm from "../src/components/publisher-form";
import PublisherSaver from "../src/components/publisher-saver";
// Databases
import DatabasesList from "../src/components/database-list";
import DatabaseSaver from "../src/components/database-saver";
// User Profile
import ProfileForm from "../src/components/profile-form";
import ProfileSaver from "../src/components/profile-saver";
// Reports
import ReportForm from "../src/components/report-form";
import ReportDownloader from "../src/components/report-downloader";

window.onload = function() {
  const metas = document.querySelector("meta[name='audience_panel']");
  const outsidelinks = document.querySelectorAll("a.menu-item, nav.breadcrumb > ul > li > a");
  for (const button of outsidelinks) {
    button.addEventListener("click", function(event) {
      if (metas != null && metas.content == "editing") {
        event.preventDefault();
        Dialog.confirm({
          title: "Warning",
          message: "are you sure you want to leave the page without saving?",
          confirmText: "Confirm",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => {
            document.querySelector("meta[name='audience_panel']").content = 'forced'
            button.click();
          },
          onCancel: () => {
            return null;
          },
        });
      }
    });
  };

  Init();
  Notify();  
  // Advertisers
  AdvertiserForm();
  AdvertiserSaver();
  // Segments
  SegmentForm();
  SegmentSaver();
  SegmentCloner();
  SegmentArchiver();
  SegmentStatsBox();
  SegmentStatsUploader();
  // Campaigns
  CampaignForm();
  CampaignToolBar();
  CampaignCloner();
  CampaignStopper();
  // Traffic
  TrafficForm();
  TrafficToolBar();
  // Databases
  DatabasesList();
  DatabaseForm();
  DatabaseSaver();
  // Customers
  CustomerForm();
  CustomerSaver();
  // Publishers
  PublisherForm();
  PublisherSaver();
  // User Profile
  ProfileForm();
  ProfileSaver();
  // Reports
  ReportForm();
  ReportDownloader();
  // Categories
  CategoryForm();
  CategorySaver();
  
  //ExcludedUrls
  ExcludedUrlForm();
  ExcludedUrlSaver();

  // Others
  Tooltip();
};
