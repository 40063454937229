<template>
  <a class="button is-small" @click="dajece()">
    <i class="fas fa-sync"></i>
  </a>
</template>

<script>
import axios from "axios";
import moment from "moment";

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  props: {
    row_id: {
        type: String,
        required: true
    },
    segment: {
        type: String,
        required: true
    },    
  },
  methods: {
    async dajece(){
      const { data } = await axios.put(`/api/v1/segments/${this.segment}/update_stats`);

      let updated_at = moment(new Date(data.updated_at)).format("DD/MM/YYYY HH:mm")
      let tr = document.querySelector(`#${this.row_id}`)
      tr.querySelector('td[role="mail"]').innerHTML = data.target_stats.mail
      tr.querySelector('td[role="sms"]').innerHTML = data.target_stats.sms
      tr.querySelector('td[role="postal"]').innerHTML = data.target_stats.postal
      tr.querySelector('td[role="updated-at"]').innerHTML = updated_at
    },
  },
}
</script>