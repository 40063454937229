<template>
    <CardBox>
        <template slot="title">Main Data</template>
        <template slot="title-right">
            <b-switch v-model="publisher.active" true-value="true" false-value="false">Active</b-switch>
        </template>
        <div class="columns is-multiline is-vcentered">
            <div class="column is-6">
                <b-field
                    :type="{ 'is-danger': hasError('name') }"
                    label="Name"
                    label-position="on-border"
                    expanded
                >
                    <b-input
                        v-model="publisher.name"
                        placeholder="insert name"
                        type="text"
                        required
                    ></b-input>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field
                    :type="{ 'is-danger': hasError('contact_person') }"
                    label="Contact Person"
                    label-position="on-border"
                    expanded
                >
                    <b-input
                        v-model="publisher.contact_person"
                        placeholder="insert contact person"
                        type="text"
                        required
                    ></b-input>
                </b-field>
            </div>
            <div class="column is-4">
                <b-field
                    :type="{ 'is-danger': hasError('email') }"
                    label="Email"
                    label-position="on-border"
                    expanded
                >
                    <b-input
                        v-model="publisher.email"
                        placeholder="insert email"
                        type="email"
                        required
                    ></b-input>
                </b-field>
            </div>
            <div class="column is-4">
                <b-field
                    :type="{ 'is-danger': hasError('phone') }"
                    label="Phone"
                    label-position="on-border"
                    expanded
                >
                    <b-input v-model="publisher.phone" placeholder="insert phone" type="text"></b-input>
                </b-field>
            </div>
            <div class="column is-4">
                <b-field
                    :type="{ 'is-danger': hasError('vat_number') }"
                    label="Vat number"
                    label-position="on-border"
                    expanded
                >
                    <b-input
                        v-model="publisher.vat_number"
                        placeholder="insert vat number"
                        type="text"
                    ></b-input>
                </b-field>
            </div>
            <div class="column is-12">
                <b-field
                    :type="{ 'is-danger': hasError('address') }"
                    label="Address"
                    label-position="on-border"
                    expanded
                >
                    <b-input v-model="publisher.address" placeholder="insert address" type="text"></b-input>
                </b-field>
            </div>
        </div>
    </CardBox>
</template>

<script>
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
import FormComponent from "../../form-component";
export default {
    extends: FormComponent,
    layout: "private",
    computed: {
        ...mapState({
            publisher: (state) => state.PublisherStore.publisher,
            errors: (state) => state.PublisherStore.errors,
        }),
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.$store.dispatch(
                "PublisherStore/setCurrent",
                this.$attrs.preload
            );
        }
    },
    components: {
        CardBox,
    },
};
</script>
