<template>
    <div class="box is-shadowless">
        <div :key="`pastdem_${filter.uuid}_${index}`" v-for="(row, index) in inner_rows">
            <div
                v-bind:key="`and_divider_${index}`"
                class="is-divider"
                data-content="AND"
                v-if="index > 0"
            ></div>

            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <b-button
                            :disabled="inner_rows.length == 1"
                            size="is-small"
                            icon-right="times"
                            @click="removeRow(index)"
                        />
                    </div>
                    <div class="level-item">
                        <b-select
                            v-model="inner_rows[index].type"
                            size="is-small"
                            placeholder="Select a type"
                            @input="inner_rows[index].value = []"
                        >
                            <option
                                v-for="option in types"
                                :value="option"
                                :key="`opt_${option}_${index}`"
                                :disabled="isUsedType(option)"
                            >{{ option | titleize }}</option>
                        </b-select>
                    </div>
                    <div class="level-item">
                        <b-datepicker
                            v-model="inner_rows[index].value"
                            v-if="inner_rows[index].type == 'date'"
                            size="is-small"
                            ref="datepicker"
                            expanded
                            icon="calendar"
                            placeholder="Select a date"
                            range
                        ></b-datepicker>

                        <div v-if="inner_rows[index].type == 'categories'">
                            <b-button
                                class="has-badge-rounded"
                                size="is-small"
                                type="is-primary"
                                :data-badge="inner_rows[index].value.length"
                                @click="inner_rows[index].isModalActive = true"
                            >categories</b-button>

                            <multiselect-modal
                                v-model="inner_rows[index].value"
                                title="categories"
                                field_search="name_en"
                                field_label="name_en"
                                :active="inner_rows[index].isModalActive"
                                :collection="categories"
                                cname="categories"
                                v-on:close="inner_rows[index].isModalActive = false"
                            ></multiselect-modal>
                        </div>

                        <div v-if="inner_rows[index].type == 'campaigns'">
                            <b-button
                                class="has-badge-rounded"
                                size="is-small"
                                type="is-primary"
                                :data-badge="inner_rows[index].value.length"
                                @click="inner_rows[index].isModalActive = true"
                            >campaigns</b-button>

                            <multiselect-modal
                                v-model="inner_rows[index].value"
                                title="campaigns"
                                field_search="name"
                                field_label="name"
                                :active="inner_rows[index].isModalActive"
                                :collection="campaigns"
                                cname="campaigns"
                                v-on:close="inner_rows[index].isModalActive = false"
                            ></multiselect-modal>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <b-button
                        v-if="index == 0"
                        size="is-small"
                        icon-right="plus"
                        :disabled="inner_rows.length == types.length"
                        @click="addRow()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MultiselectModal from "../../elements/multiselect-modal";
import { mapState } from "vuex";
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            types: ["date", "campaigns", "categories"],
            inner_rows: [],
        };
    },
    computed: {
        ...mapState({
            categories: (state) => state.AnagStore.categories,
            campaigns: (state) => state.AnagStore.pastdems,
        }),
        choosed() {
            let pl = {};
            this.inner_rows.map((i) => {
                if (i.type !== null && i.value.length !== 0) {
                    pl[`${i.type}`] = i.value;
                }
                return;
            });
            return pl;
        },
        isValidFilter() {
            let used_keys = Object.keys(this.choosed);
            if (used_keys.length === 0 && this.choosed.constructor === Object) {
                return false;
            }
            if (used_keys.length === this.inner_rows.length) {
                return true;
            }
            return false;
        },
        payload(){
            return {
                reference: this.filter,
                value: this.isValidFilter ? this.choosed : null,
            };
        }
    },
    watch: {
        payload: {
            deep: true,
            handler() {
                this.$store.dispatch(
                    "SegmentStore/updateFilterValue",
                    this.payload
                );
            },
        },
    },
    methods: {
        addRow() {
            this.inner_rows.push({
                type: null,
                value: [],
                isModalActive: false,
            });
        },
        removeRow(index) {
            this.inner_rows.splice(index, 1);
        },
        isUsedType(type) {
            return (
                this.inner_rows.filter((row) => row.type === type).length == 1
            );
        },
    },
    mounted() {
        this.$store.dispatch("AnagStore/listCategories");
        this.$store.dispatch("AnagStore/listPastDems");
        if (this.filter.value == null) {
            this.addRow();
        }
        else{
            let keys = Object.keys(this.filter.value)
            keys.forEach((k) => {
                let values = this.filter.value[k]
                if(k == 'date'){
                    values = values.map((d) => {
                        return new Date(d.split("T")[0])
                    })                    
                }               
                this.inner_rows.push({
                    type: k,
                    value: values,
                    isModalActive: false,
                });
            });
        }
    },
    components: {
        MultiselectModal,
    },
};
</script>