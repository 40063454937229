<template>
    <div>
        <card-box :paddingless="true">
            <template slot="title">Accounts</template>
            <template slot="title-right">
                <b-button
                    size="is-small"
                    icon-left="plus"
                    type="is-primary"
                    @click="setCurrentAccount()"
                >Add</b-button>
            </template>
            <div>
                <table class="table is-border is-fullwidth">
                    <thead>
                        <tr>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>Email</th>
                            <th class="has-text-centered">Active?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-bind:key="`user_${index}`" v-for="(user, index) in users">
                            <td>{{ user.firstname }}</td>
                            <td>{{ user.lastname }}</td>
                            <td>{{ user.email }}</td>
                            <td class="has-text-centered">{{ user.active }}</td>
                            <td>
                                <b-button
                                    class="is-pulled-right"
                                    size="is-small"
                                    type="is-primary"
                                    @click="setCurrentAccount(index)"
                                >Edit</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-box>

        <b-modal
            :active.sync="isActiveModal"
            trap-focus
            has-modal-card
            :can-cancel="false"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div class="modal-card" style="width: auto">
                <div>
                    <header class="modal-card-head">
                        <div class="modal-card-title level">
                            <div class="level-left">
                                <div class="level-item">Create new account</div>
                            </div>
                            <div class="level-left">
                                <div class="level-right">
                                    <b-switch
                                        v-model="current_user.active"
                                        true-value="true"
                                        false-value="false"
                                    >ACTIVE</b-switch>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section class="modal-card-body">
                        <b-field grouped>
                            <b-field label="Firstname" expanded>
                                <b-input v-model="current_user.firstname"></b-input>
                            </b-field>
                            <b-field label="Lastname" expanded>
                                <b-input v-model="current_user.lastname"></b-input>
                            </b-field>
                        </b-field>
                        <b-field label="Email" expanded>
                            <b-input v-model="current_user.email" type="email"></b-input>
                        </b-field>
                        <b-field label="Password" expanded>
                            <b-input
                                v-model="current_user.password"
                                type="password"
                                password-reveal
                            ></b-input>
                        </b-field>
                        <b-field label="Password confirmation" expanded>
                            <b-input
                                v-model="current_user.password_confirmation"
                                type="password"
                                password-reveal
                            ></b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button" type="button" @click="isActiveModal = false">Close</button>
                        <button v-if="!isEdit" type="submit" class="button is-primary" @click="addUser">Add</button>                        
                    </footer>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
const empty_user = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password_confirmation: "",
    active: true,
};
import CardBox from "../elements/card-box";
export default {
    props: {
        accounts: {            
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            current_user: {
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                password_confirmation: "",
                active: true,
            },            
            users: this.accounts.map((u) => {
                return {
                    id: u.id,
                    firstname: u.firstname,
                    lastname: u.lastname,
                    email: u.email,
                    active: u.active,
                    password: "",
                    password_confirmation: "",
                };
            }),
            isActiveModal: false,
            isEdit: false,
        };
    },
    computed: {
        payload(){
            return this.users
        }
    },
    watch: {        
        payload: {
            deep: true,
            handler(){
                this.$emit("change", this.payload);
            }
        }
    },
    methods: {
        addUser() {
            this.isEdit = false
            this.users.push(this.current_user);            
            this.current_user = empty_user;            
            this.isActiveModal = false;
        },
        setCurrentAccount(index = null) {
            if (index == null) {
                this.isEdit = false
                this.current_user = {
                    firstname: "",
                    lastname: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    active: true,
                };
            }
            else{
             this.isEdit = true
             this.current_user = this.users[index]
            }
            this.isActiveModal = true;
        },
    },
    components: {
        CardBox,
    },
};
</script>