<template>
    <section>
        <div class="buttons">
            <b-button class="is-primary">
                <span v-if="current_country !== undefined">{{ current_country.name_en }}</span>
            </b-button>

            <b-button
                :data-badge="regions.length"
                class="has-badge-rounded is-primary"
                @click="activateModal('regions')"
            >Regions</b-button>

            <b-button
                v-if="regions.length > 0"
                :data-badge="districts.length"
                class="has-badge-rounded is-primary"
                @click="activateModal('districts')"
            >Districts</b-button>

            <b-button
                v-if="districts.length > 0"
                :data-badge="cities.length"
                class="has-badge-rounded is-primary"
                @click="activateModal('cities')"
            >Cities</b-button>
        </div>

        <multiselect-modal
            v-if="context.name === 'regions'"
            :disabled="disabled"
            v-model="regions"
            :title="context.name"
            :active.sync="isModalActive"
            :collection="context.collection"
            :cname="context.name"
            v-on:close="isModalActive = false"
        ></multiselect-modal>

        <districts
            v-if="context.name === 'districts'"
            v-model="districts"
            title="districts"
            :regions="collections.regions.filter(i => regions.includes(i.id) )"
            :active.sync="isModalActive"
            :collection="context.collection"
            cname="districts"
            v-on:close="isModalActive = false"
        ></districts>

        <cities
            v-if="context.name === 'cities'"
            v-model="cities"
            title="cities"
            :districts="collections.districts.filter(i => districts.includes(i.id) )"
            :active.sync="isModalActive"
            :collection="context.collection"
            cname="cities"
            v-on:close="isModalActive = false"
        ></cities>
    </section>
</template>

<script>
import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import MultiselectModal from "../../elements/multiselect-modal";
import Districts from "./geo/districts";
import Cities from "./geo/cities";
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
        selected_country: {
            type: String,
            required: false,
            default: "IT",
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            collections: {
                countries: [],
                regions: [],
                districts: [],
                cities: [],
            },
            countries: [],
            regions: [],
            districts: [],
            cities: [],
            // MultiselectModals
            isModalActive: false,
            choose_all: false,
            isLoading: true,
            search_term: null,
            context: {
                name: "",
                collection: [],
            },
        };
    },
    computed: {
        current_country() {
            return this.collections.countries[0];
        },
        payload() {
            let values = {};
            if (this.current_country !== undefined) {
                values = { countries: this.countries };
            }
            ["regions", "districts", "cities"].forEach((i) => {
                if (this[i] !== null && this[i].length > 0) values[i] = this[i];
            });
            return values;
        },
    },
    methods: {
        async getRegionsByCountry(country) {
            this.countries.push(country);
            const { data } = await axios.get(
                `/api/v1/geo/countries/${country}/regions`
            );
            this.collections.regions = data;
        },
        async getDistrictsByRegions(ids) {
            const { data } = await axios.get(`/api/v1/geo/districts`, {
                params: {
                    ids: ids,
                },
            });
            this.collections.districts = data;
        },
        async getCitiesByDistricts(ids) {
            const { data } = await axios.get(`/api/v1/geo/cities`, {
                params: {
                    ids: ids,
                },
            });
            this.collections.cities = data;
        },
        activateModal(ctx) {
            this.search_term = "";
            this.choose_all = false;
            switch (ctx) {
                case "regions":
                    this.context = {
                        multiselect: true,
                        collection: this.collections.regions,
                        name: "regions",
                    };
                    break;
                case "districts":
                    let districts = this.collections.districts;
                    this.context = {
                        multiselect: true,
                        collection: districts,
                        name: "districts",
                    };
                    break;
                case "cities":
                    let cities = this.collections.cities;
                    this.context = {
                        multiselect: true,
                        collection: cities,
                        name: "cities",
                    };
                    break;
                default:
                    return null;
            }
            this.isModalActive = true;
        },
    },
    async mounted() {
        const { data } = await axios.get("/api/v1/geo/countries");
        this.collections.countries = data;
        this.getRegionsByCountry(data[0].id);
        let preload = this.filter.value;
        if (preload !== null) {
            ["regions", "districts", "cities"].forEach((i) => {
                if (preload[i] !== null && preload[i].length > 0) this[i] = preload[i];
            });
        }
    },
    watch: {
        regions() {
            if (this.regions.length > 0) {
                this.getDistrictsByRegions(this.regions);
            }
            else{
                this.districts = []
                this.cities = []
            }
        },
        districts() {
            if (this.districts.length > 0) {
                this.getCitiesByDistricts(this.districts);
            }
            else{
                this.cities = []
            }
        },
        payload() {
            this.$store.dispatch("SegmentStore/updateFilterValue", {
                reference: this.filter,
                value: this.payload,
            });
        },
    },
    components: {
        MultiselectModal,
        Districts,
        Cities,
    },
};
</script>