<template>
    <section>
        <b-button
            type="is-primary"
            v-if="!isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="createAdvertiser"
        >Save</b-button>
        <b-button
            type="is-primary"
            v-if="isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateAdvertiser"
        >Save</b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import Buefy from "buefy";
export default Vue.component("advertiser-saver", {
    computed: {
        ...mapState({
            advertiser: state => state.AdvertiserStore.advertiser
        }),
        isEdit(){
            return this.advertiser.hasOwnProperty('id') && this.advertiser.id != null
        },
        isSavable() {
            return (
                this.advertiser.name !== ''
            );
        }          
    },
    methods: {
        createAdvertiser(){
            this.$store.dispatch("AdvertiserStore/save", this.advertiser);
        },
        updateAdvertiser() {
            this.$store.dispatch("AdvertiserStore/update", this.advertiser);            
        }
    }    
});
</script>