import Vue from 'vue/dist/vue.esm'
import TrafficToolbar from './vue/traffic-form/toolbar'
import store from '../vuex';


export default function() {
  if (document.querySelector('traffic-toolbar')) {
    return new Vue({
      el: 'traffic-toolbar',
      components: { TrafficToolbar },
      store
    })
  }
}