<template>
    <section>
        <b-button
            v-if="!isEdit"
            class="button is-primary"
            icon-left="save"
            :disabled="!isSavable"
            @click="savePrompt"
        >Save As</b-button>
        <b-button
            v-if="isEdit"
            class="button is-primary"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateSegment"
        >Save</b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import Buefy from "buefy";
export default Vue.component("segment-saver", {
    data() {
        return {
            isValid: true,
        };
    },
    computed: {
        ...mapState({
            segment: (state) => state.SegmentStore.segment,
            filters: (state) =>
                [].concat(
                    ...state.SegmentStore.segment.audience_boxes.map(
                        (i) => i.filters
                    )
                ),
            valids: (state) =>
                []
                    .concat(
                        ...state.SegmentStore.segment.audience_boxes.map(
                            (i) => i.filters
                        )
                    )
                    .filter((i) => i.type !== null && i.value !== null),
        }),
        isEdit() {
            return this.segment.hasOwnProperty("id") && this.segment.id != null;
        },
        hasValidFilters() {
            return this.filters.length > 0
                ? this.valids.length === this.filters.length
                : true;
        },
        isSavable() {
            return (
                this.segment.publisher_ids.length > 0 &&
                this.segment.dbs.length > 0 &&
                this.hasValidFilters
            );
        },
    },
    methods: {
        savePrompt() {
            this.$buefy.dialog.prompt({
                message: `Save segment as:`,
                inputAttrs: {
                    placeholder: "segment name",
                    maxlength: 50,
                },
                trapFocus: true,
                onConfirm: (value) => {
                    this.$store.dispatch("SegmentStore/save", value);
                },
            });
        },
        updateSegment() {
            this.$store.dispatch("SegmentStore/update", this.segment);
        },
    },
    watch: {
        segment: {
            deep: true,
            handler(c) {
                this.$store.dispatch("SegmentStore/setLoading", true);
                this.$store.dispatch("SegmentStore/getStats");
            },
        },
    },
});
</script>