<template>
    <div>
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false">
            <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
        </b-loading>
        <b-modal
            :active="campaign.type === null && !has_preload"
            has-modal-card
            trap-focus
            :can-cancel="false"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-centered">CHOOSE TYPE</p>
                </header>
                <section class="modal-card-body">
                    <b-field>
                        <b-radio-button v-model="ctype" native-value="mail" type="is-primary">
                            <b-icon icon="envelope"></b-icon>
                            <span>MAIL</span>
                        </b-radio-button>

                        <b-radio-button
                            v-model="ctype"
                            native-value="sms"
                            type="is-primary"
                        >
                            <b-icon icon="sms"></b-icon>
                            <span>SMS</span>
                        </b-radio-button>

                        <b-radio-button
                            v-model="ctype"
                            native-value="postal"
                            type="is-primary"
                            disabled
                        >
                            <b-icon icon="address-card"></b-icon>
                            <span>POSTAL</span>
                        </b-radio-button>
                    </b-field>
                </section>
                <footer class="modal-card-foot" v-if="ctype !== null">
                    <b-button
                        @click="campaign.type = ctype"
                        size="is-small"
                        type="is-success"
                        expanded
                    >DONE</b-button>
                </footer>
            </div>
        </b-modal>

        <div v-if="campaign.type !== null">
            <div class="level">
                <div class="level-left">
                    <h4 class="title is-6">
                        <b-icon :icon="icon_class" size="is-small"></b-icon>
                        <span>{{ campaign.type | uppercase}} CAMPAIGN</span>
                    </h4>
                </div>
                <div class="level-right"></div>
            </div>

            <settings-panel
                :show="show"
                v-on:update-settings="updateCampaign($event)"
                v-on:advertiser-added="updateAdvertiserList()"
                v-on:open-sent-mode="switchSentModePanel"
            />
            <br />
            <mail-content-editor v-if="campaign.type == 'mail'" :show="show" />
            <sms-content-editor v-if="campaign.type == 'sms'" :show="show" />
        </div>
    </div>
</template>

<script>
import FormComponent from "../../form-component";
import MailContentEditor from "./mail-content-editor";
import SmsContentEditor from "./sms-content-editor";
import SettingsPanel from "./settings-panel";
import { mapState } from "vuex";
export default {
    extends: FormComponent, 
    props: {
        "context-url": {
            type: String,
            default: "",
        },
        "customer-area": {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ctype: null,
        };
    },
    computed: {
        ...mapState({
            campaign: (state) => state.CampaignStore.campaign,
            main_url: (state) => state.CampaignStore.contextUrl,
            advertisers: (state) => state.CampaignStore.advertisers,
            customers: (state) => state.CampaignStore.customers,
            segments: (state) => state.CampaignStore.segments,
            categories: (state) => state.AnagStore.categories,
            isLoading: (state) => state.CampaignStore.isLoading,
        }),
        has_preload() {
            return this.$attrs.hasOwnProperty("preload");
        },
        icon_class() {
            switch (this.campaign.type) {
                case "sms": {
                    return "sms";
                    break;
                }
                case "postal": {
                    return "address-card";
                    break;
                }
                default: {
                    return "envelope";
                    break;
                }
            }
        },
    },
    watch: {
        campaign: {
            deep: true,
            handler(c, old) {
                if (
                    !old.hasOwnProperty("id") &&
                    c.hasOwnProperty("id") &&
                    !this.$attrs.hasOwnProperty("preload")
                ) {
                    this.$buefy.dialog.confirm({
                        message: "Draft Saved!",
                        canCancel: false,
                        confirmText: "OK",
                        onConfirm: () =>
                            (window.location.href = `${this.main_url}/${c.id}/edit`),
                    });
                }
            },
        },
    },
    components: {
        SettingsPanel,
        MailContentEditor,
        SmsContentEditor
    },
    methods: { 
        isDraft() {
            return this.campaign.state == "draft";
        },
        canBeSent() {
            return (
                this.campaign.sent_mode_customer !== null &&
                this.campaign.scheduling_customer.length > 0
            );
        },
        switchSentModePanel() {
            this.$store.dispatch("CampaignStore/switchSentModePanel");
        },
        updateCampaign(payload) {
            this.$store.dispatch("CampaignStore/updateCampaign", payload);
        },
        updateAdvertiserList() {
            setTimeout(() => {
                this.$store.dispatch("CampaignStore/listAdvertisers");
            }, 1000);            
        },        
    },
    mounted() {
        if (this.$attrs.hasOwnProperty("preload")) {
            this.updateCampaign(this.$attrs.preload);
        }
        this.$store.dispatch("CampaignStore/setContextUrl", this.contextUrl);
        this.$store.dispatch("CampaignStore/listAdvertisers");
        this.$store.dispatch("CampaignStore/listCustomers");
        this.$store.dispatch("CampaignStore/listSegments");
        this.$store.dispatch("AnagStore/listCategories");
    },
};
</script>