<template>
    <div :class="baseClass">
        <header :class="{'modal-card-head': modal, 'card-header': !modal}" v-if="hasSlot('title')">
            <div :class="{'card-header-title': !modal, 'modal-card-title': modal, level: true}">
                <div class="level-left">
                    <div class="level-item">
                        <slot name="title"></slot>
                    </div>
                    <div class="level-item" v-if="hasSlot('search-field')">
                        <slot name="search-field"></slot>
                    </div>
                </div>

                <div class="level-right">
                    <div class="level-item" v-if="hasSlot('title-right')">
                        <slot name="title-right"></slot>
                    </div>
                    <a @click="expanded = !expanded" class="level-item" v-if="expandable">                
                        <b-icon size="is-small" icon="expand"></b-icon>
                    </a>
                    
                </div>
            </div>
        </header>
        <div :class="{'modal-card-body': modal, 'card-content': !modal, 'is-paddingless': paddingless}">
            <slot></slot>
        </div>
        <footer
            class="card-footer is-block"
            v-if="hasSlot('footer-left') || hasSlot('footer-right')"
        >
            <nav class="level">
                <div class="level-left">
                    <slot name="footer-left"></slot>
                </div>
                <div class="level-right">
                    <slot name="footer-right"></slot>
                </div>
            </nav>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        modal: {
            type: Boolean,
            false: true,
            default: false
        },
        expandable: {
            type: Boolean,
            false: true,
            default: false
        },
        fullheight: {
            type: Boolean,
            false: true,
            default: false
        },
        paddingless: {
            type: Boolean,
            default: false            
        }        
    },
    data(){
        return{
            expanded: false
        };
    },
    computed: {
        baseClass() {
            return {
                card: !this.modal,
                "modal-card": this.modal,
                "is-fullscreen": this.expanded,
                'is-fullheight': this.fullheight
            };
        }
    },
    methods: {
        buildClass(classname) {
            return this.modal ? `modal-${classname}` : classname;
        },
        hasSlot(name = "default") {
            return !!this.$slots[name] || !!this.$scopedSlots[name];
        }
    }
};
</script>