import Vue from 'vue/dist/vue.esm'
import CustomerSaver from './vue/customer-form/saver'
import store from '../vuex';


export default function() {
  if (document.querySelector('customer-saver')) {
    return new Vue({
      el: 'customer-saver',
      components: { CustomerSaver },
      store
    })
  }
}