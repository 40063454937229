<template>
  <card-box>
    <template slot="title">Content</template>
    <b-field
      :type="{ 'is-danger': hasError('subject') }"
      label="Message"
      label-position="on-border"
    >
      <b-input
        :disabled="campaign.segment_id == null || show"
        v-model="campaign.message_text"
        placeholder="insert subject"
        maxlength="160"
        type="textarea"
      ></b-input>
    </b-field>
  </card-box>
</template>

<script>
import CardBox from "../elements/card-box";
import FormComponent from "../../form-component";
import { mapState } from "vuex";
export default {
  extends: FormComponent,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      campaign: (state) => state.CampaignStore.campaign,
      errors: (state) => state.CampaignStore.errors,
    }),
  },
  components: {
    CardBox,
  },
};
</script>