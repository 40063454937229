<template>
    <div class="field is-grouped">
        <div class="control">
            <div class="field has-addons">
                <p class="control">
                    <span class="button is-static">{{ filter.context | titleize }}</span>
                </p>
                <div class="control is-clearfix">
                    <span class="select">
                        <select v-model="selected" @change="changeFilterType" :disabled="disabled">
                            <option value></option>
                            <option
                                v-for="option in filter_options"
                                model="selected"
                                :disabled="!availableFilters.includes(option.name)"
                                :value="option.name"
                                :key="`${filter.uuid}_${filter.context}_${option.name}`"
                            >{{ option.title }}</option>
                        </select>
                    </span>
                </div>
            </div>
        </div>
        <div class="control is-expanded dynamic-filter">
            <component
                v-if="selected != ''"
                v-bind:disabled="disabled"
                v-bind:is="derivedFilter.component"
                :key="`cmp_${derivedFilter.name}`"
                v-bind:settings="derivedFilter"
                v-bind:filter="filter"
            ></component>
        </div>
        <div class="control" v-if="!disabled">
            <b-button size="is-small" icon-right="trash" @click="removeFilter(filter)" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        filter: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selected: this.filter.type || ""
        };
    },
    computed: {
        ...mapState({
            filter_contexts: state => state.FilterStore.contexts,
            audience_boxes: state => state.SegmentStore.segment.audience_boxes
        }),
        derivedFilter() {
            return this.$store.getters["FilterStore/derivedFilter"](
                this.filter.context,
                this.selected
            );
        },
        filter_options() {
            return this.filter_contexts[this.filter.context];
        },
        availableFilters() {
            let available_types = this.filter_options.map(i => i.name);
            let box = this.audience_boxes.find(i => i.uuid === this.filter.box);
            let others = box.filters.filter(f => f.uuid !== this.filter.uuid);
            if (others.length > 0) {
                available_types = available_types.filter(function(item) {
                    return !others.map(i => i.type).includes(item);
                });
            }
            return available_types;
        }
    },
    methods: {
        removeFilter(filter) {
            this.$store.dispatch("SegmentStore/removeFilter", filter);
        },
        changeFilterType(e) {
            e.preventDefault();
            let payload = {
                reference: this.filter,
                value: e.target.value
            };
            this.$store.dispatch("SegmentStore/updateFilterType", payload);
        }
    }
};
</script>
