import Vue from 'vue/dist/vue.esm'
import CampaignCloner from './vue/campaign-form/campaign-cloner'
import store from '../vuex';

export default function () {
    const cloners = document.querySelectorAll("campaign-cloner")
    for (const el of cloners) {
        new Vue({
            el: el,
            components: { CampaignCloner },
            store,
            render(createElement) {
                return createElement('campaign-cloner', {
                    props: {
                        campaign: el.attributes.campaign.value
                    }
                })
            }
        })
    }
}