<template>
    <button class="button is-small" @click="duplicate">Duplicate</button>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
import Buefy from "buefy";
export default Vue.component("campaign-cloner", {
    props: {
        campaign: {
            type: String,
            default: null
        }
    },
    methods: {
        duplicate() {
            this.$buefy.dialog.prompt({
                message: `Duplicate this campaign as:`,
                inputAttrs: {
                    placeholder: "campaign name",
                    maxlength: 50
                },
                trapFocus: true,
                onConfirm: value => {
                    this.$store.dispatch("CampaignStore/clone", {
                        id: this.campaign,
                        name: value
                    });
                }
            });
        }
    }
});
</script>