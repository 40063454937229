<template>
    <section>    
        <b-button 
            type="is-primary"
            v-if="!isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="createExcludedUrl">
            Save
        </b-button>
        <b-button 
            type="is-primary"
            v-if="isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateExcludedUrl">
            Save
        </b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
export default Vue.component("excluded-url-saver", {
  computed: {
      ...mapState({
          excluded_url: state => state.ExcludedUrlStore.excluded_url
      }),
      isEdit(){
          return this.excluded_url.hasOwnProperty('id') && this.excluded_url.id != null
      },
      isSavable() {
           return ( this.excluded_url.url !== '' );
      },
      payload(){
          return {
            ...this.excluded_url
          };
      }          
  },
  methods: {
      async createExcludedUrl(){
          this.$store.dispatch("ExcludedUrlStore/save", this.excluded_url);
      },
      updateExcludedUrl() {
          this.$store.dispatch("ExcludedUrlStore/update", this.excluded_url);
      },
  }
})

</script>