<template>
    <section>    
        <b-button 
            type="is-primary"
            v-if="!isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="createCategory">
            Save
        </b-button>
        <b-button 
            type="is-primary"
            v-if="isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateCategory">
            Save
        </b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
export default Vue.component("category-saver", {
  computed: {
      ...mapState({
          category: state => state.CategoryStore.category
      }),
      isEdit(){
          return this.category.hasOwnProperty('id') && this.category.id != null
      },
      isSavable() {
          return (
            this.category.code !== '' && 
            this.category.name_en !== '' && 
            this.category.name_it !== ''
          );
      },
      payload(){
          return {
            ...this.category
          };
      }          
  },
  methods: {
      async createCategory(){
          this.$store.dispatch("CategoryStore/save", this.category);
      },
      updateCategory() {
          this.$store.dispatch("CategoryStore/update", this.category);
      },
  }
})

</script>