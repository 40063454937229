import Vue from 'vue/dist/vue.esm'
import DatabaseSaver from './vue/database-form/saver'
import store from '../vuex';


export default function() {
  if (document.querySelector('database-saver')) {
    return new Vue({
      el: 'database-saver',
      components: { DatabaseSaver },
      store
    })
  }
}