import Vue from 'vue/dist/vue.esm'
import ExcludedUrlSaver from './vue/excluded-url-form/saver'
import store from '../vuex';


export default function () {
  if (document.querySelector('excluded-url-saver')) {
    return new Vue({
      el: 'excluded-url-saver',
      components: { ExcludedUrlSaver },
      store
    })
  }
}