<template>
    <b-field>
        <b-slider :disabled="disabled" v-model="agerange" :min="0" :max="100" :step="1"></b-slider>
    </b-field>
</template>

<script>
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        }        
    },    
    data(){
        return {
            agerange: [18, 100]
        }
    },
    watch: {
        agerange(){
            let payload = {
                reference: this.filter,
                value: this.agerange
            };
            this.$store.dispatch("SegmentStore/updateFilterValue", payload);
        }
    },
    mounted(){
        if(this.filter.value !== null){
           this.agerange =  this.filter.value
        }
    }  
}
</script>
