<template>
 <b-tooltip :label="label">
    {{ text }}
 </b-tooltip>
</template>

<script>
export default {
 props: ['label', 'text']
}
</script>