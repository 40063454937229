import Vue from "vue/dist/vue.esm";
import Buefy from "buefy";

Vue.filter("titleize", function(value) {
  if (!value) return "";
  value = value
    .toString()
    .replace("_", " ")
    .replace("-", " ");
  return value.replace(/\b\w/g, (l) => l.toUpperCase());
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  return value.toUpperCase();
});

Vue.use(Buefy, { defaultIconPack: "fa" });
export default () => {};
