<template>
    <div>
<card-box v-if="account != 'bronze'">
        <template slot="title">Publishers</template>
        <b-field>
            <b-taginput
                v-model="choosed_publishers"
                :data="filteredPublishers"
                autocomplete
                :open-on-focus="true"
                field="name"
                icon="tag"
                :disabled="disabled"
                type="is-primary"
                placeholder="Add publishers"
                @add="addPublisher"
                @remove="removePublisher"
                @typing="filterPublishers"
                append-to-body
            ></b-taginput>
        </b-field>

        <div class="columns is-multiline" v-if="account == 'gold'">
            <div                
                class="column is-narrow"
                v-bind:key="`pubs_${index}`"
                v-for="(publisher, index) in choosed_publishers"
            >
                <b-message
                    :title="`${publisher.name} DB(s)`"
                    size="is-small"
                    type="is-primary"
                    :closable="false"
                    @close="removePublisher(publisher)"
                >
                    <section>
                        <div class="block">
                            <b-checkbox
                                :disabled="disabled"
                                :key="`dbs_${index}`"
                                v-for="(db, index) in publisher.lists"
                                :native-value="db.id"
                                v-model="segment.dbs"
                            >{{ db.name }}</b-checkbox>
                        </div>
                    </section>
                </b-message>
            </div>
        </div>        
    </card-box>
    </div>    
</template>

<script>
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
export default {
    props:{
        disabled: {
            type: Boolean,
            default: false
        },
        account: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            search_term: ""
        };
    },
    computed: {
        ...mapState({
            segment: state => state.SegmentStore.segment,
            publishers: state => state.AnagStore.publishers
        }),
        choosed_publishers: {
            get(){
                return  this.publishers.filter(
                    p => this.segment.publisher_ids.includes(p.id)
                );
            },
            set(val){
                let ids = val.map(p => p.id)
                this.segment.publisher_ids = ids;                
            }
        },
        filteredPublishers() {
            let ret = this.publishers.filter(
                p => !this.segment.publisher_ids.includes(p.id)
            );
            if (this.search_term !== "") {
                ret = ret.filter(
                    option =>
                        option.name
                            .toString()
                            .toLowerCase()
                            .indexOf(this.search_term.toLowerCase()) >= 0
                );
            }
            return ret;
        }
    },
    watch: {
        filteredPublishers(){
            if(this.account == 'bronze'){
                this.filteredPublishers.forEach(p => {
                    this.addPublisher(p)
                });
            }
        }
    },
    methods: {
        filterPublishers(text) {
            this.search_term = text;
        },
        addPublisher(publisher) {
            this.segment.dbs.push.apply(
                this.segment.dbs,
                publisher.lists.map(i => i.id)
            );
        },
        removePublisher(publisher) {
            this.segment.dbs = this.segment.dbs.filter(
                i => publisher.lists.map(i => i.id).indexOf(i) === -1
            );
        }
    },
    components: {
        CardBox
    }
};
</script>