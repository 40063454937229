import {
  CheckboxListFilter,
  IntRangeFilter,
  DynamicFilter,
  GeoFilter,
  ZipUploadFilter,
  EmailDomainFilter,
  PastDemFilter,
} from "../../components/vue/segment-form/filters";

const FilterStore = {
 namespaced: true,
  state: {
    contexts: {
      determinstic: [
        {
          name: "genders",
          title: "Genders",
          component: CheckboxListFilter,
          action: "AnagStore/listGenders",
        },
        {
          name: "age",
          title: "Age range",
          component: IntRangeFilter,
          action: null,
        },
        {
          name: "geo",
          title: "Country, Regions, Districts, Cities",
          component: GeoFilter,
          action: null,
        },
        {
          name: "zip_codes",
          title: "Upload Zip Codes",
          component: ZipUploadFilter,
          action: null,
        },
        {
          name: "martial_statuses",
          title: "Martial Statuses",
          component: CheckboxListFilter,
          action: "AnagStore/listMartialStatuses",
        },
        {
          name: "studies",
          title: "Studies",
          component: DynamicFilter,
          action: "AnagStore/listStudies",
        },
        {
          name: "jobs",
          title: "Jobs",
          component: DynamicFilter,
          action: "AnagStore/listJobs",
        },
        {
          name: "employment_levels",
          title: "Employment Levels",
          component: DynamicFilter,
          action: "AnagStore/listLevels",
        },
        {
          name: "interests",
          title: "Interests",
          component: DynamicFilter,
          action: "AnagStore/listCategories",
        },
        {
          name: "owned_cars",
          title: "Owned Car",
          component: DynamicFilter,
          action: "AnagStore/listCars",
        },
        {
          name: "email_in",
          title: "Email contains",
          component: EmailDomainFilter,
          action: null,
        },
        {
          name: "email_not_in",
          title: "Email not contains",
          component: EmailDomainFilter,
          action: null,
        },
      ],
      cookie: [
        {
          name: "nmck_categories",
          title: "Categories",
          component: DynamicFilter,
          action: "AnagStore/listNmckCategories",
          field_value: "code"
        },
      ],
      past_dem: [
        {
          name: "pastdem_click",
          title: "Click",
          component: PastDemFilter,
          action: null,
        },
        {
          name: "pastdem_not_click",
          title: "Not click",
          component: PastDemFilter,
          action: null,
        },
        {
          name: "pastdem_open",
          title: "Open",
          component: PastDemFilter,
          action: null,
        },
        {
          name: "pastdem_not_open",
          title: "Not open",
          component: PastDemFilter,
          action: null,
        },
        {
          name: "pastdem_sent",
          title: "Sent",
          component: PastDemFilter,
          action: null,
        },
        {
          name: "pastdem_not_sent",
          title: "Not sent",
          component: PastDemFilter,
          action: null,
        },
      ],
    }
  },
  getters: {
    derivedFilter: (state) => (ctx, name) => {
      let filter = state.contexts[ctx].find((i) => i.name == name);
      return filter;
    }
  },
};
export default FilterStore;
