import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const AnagStore = {
  namespaced: true,
  state: {
    publishers: [],
    categories: [],
    nmck_categories: [],
    databases: [],
    pastdems: [],
    interests: [],
    genders: [],
    martial_statuses: [],
    studies: [],
    jobs: [],
    employment_levels: [],
    owned_cars: []
  },
  mutations: {
    SET_PUBLISHERS(state, data) {
      state.publishers = data;
    },
    SET_CATEGORIES(state, data) {
      state.categories = data;
      state.interests = data;
    },
    SET_NMCK_CATEGORIES(state, data) {
      state.nmck_categories = data;
    },    
    SET_DATABASES(state, data) {
      state.databases = data;
    },
    SET_PASTDEMS(state, data) {
      state.pastdems = data;
    },        
    SET_GENDERS(state, data) {
      state.genders = data;
    },
    SET_MARTIAL_STATUSES(state, data) {
      state.martial_statuses = data;
    },
    SET_STUDIES(state, data) {
      state.studies = data;
    },
    SET_JOBS(state, data) {
      state.jobs = data;
    },
    SET_LEVELS(state, data) {
      state.employment_levels = data;
    },
    SET_CARS(state, data) {
      state.owned_cars = data;
    }
  },

  getters: {
    publishers(state) {
      return state.publishers;
    },
    categories(state) {
      return state.categories;
    },
    nmck_categories(state) {
      return state.nmck_categories;
    },    
    databases(state) {
      return state.databases;
    },
    pastdems(state) {
      return state.pastdems;
    },        
    genders(state) {
      return state.genders;
    },    
    martial_statuses(state) {
      return state.martial_statuses;
    },
    studies(state) {
      return state.studies;
    },
    jobs(state) {
      return state.jobs;
    },
    employment_levels(state) {
      return state.employment_levels;
    },
    owned_cars(state) {
      return state.owned_cars;
    }
  },

  actions: {
    async listPublishers({ commit }, filter) {
      const { data } = await axios.get("/api/v1/publishers");
      commit("SET_PUBLISHERS", data);
    },
    async listCategories({ commit }) {
      const { data } = await axios.get("/api/v1/anag/categories");
      commit("SET_CATEGORIES", data);
    },
    async listNmckCategories({ commit }) {
      const { data } = await axios.get("/api/v1/anag/nmck_categories");
      commit("SET_NMCK_CATEGORIES", data);
    },    
    async listDatabases({ commit }) {
      const { data } = await axios.get("/api/v1/lists");
      commit("SET_DATABASES", data);
    },
    async listPastDems({ commit }) {
      const { data } = await axios.get("/api/v1/anag/pastdems");
      commit("SET_PASTDEMS", data);
    },        
    async listGenders({ commit }) {
      const { data } = await axios.get("/api/v1/anag/genders");
      commit("SET_GENDERS", data);
    },
    async listMartialStatuses({ commit }) {
      const { data } = await axios.get("/api/v1/anag/martial_statuses");
      commit("SET_MARTIAL_STATUSES", data);
    },
    async listStudies({ commit }) {
      const { data } = await axios.get("/api/v1/anag/studies");
      commit("SET_STUDIES", data);
    },
    async listJobs({ commit }) {
      const { data } = await axios.get("/api/v1/anag/jobs");
      commit("SET_JOBS", data);
    },
    async listLevels({ commit }) {
      const { data } = await axios.get("/api/v1/anag/levels");
      commit("SET_LEVELS", data);
    },
    async listCars({ commit }) {
      const { data } = await axios.get("/api/v1/anag/cars");
      commit("SET_CARS", data);
    }
  }
};

export default AnagStore;
