<template>
    <section>
        <button
            class="button has-badge-rounded is-primary"
            :data-badge="choosed.length"
            @click="isModalActive = true"
        >
            <span>{{ settings.title }}</span>
        </button>

        <multiselect-modal
            v-model="choosed"
            :title="settings.title"
            :field_value="settings.field_value"
            :active.sync="isModalActive"
            :collection="collection"
            :cname="settings.name"
            :disabled="$attrs.disabled"
            v-on:close="isModalActive = false"
        ></multiselect-modal>
    </section>
</template>

<script>
import MultiselectModal from "../../elements/multiselect-modal";
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isModalActive: false,
            choosed: []
        };
    },
    computed: {
        current_store() {
            let str = this.settings.action;
            return str.substr(0, str.indexOf("/"));
        },
        collection() {
            return this.$store.state[this.current_store][this.settings.name];
        },
        payload() {
            let val =
                this.choosed == null || this.choosed.length == 0
                    ? null
                    : this.choosed;
            return {
                reference: this.filter,
                value: val
            };
        }
    },
    watch: {
        choosed() {
            this.$store.dispatch(
                "SegmentStore/updateFilterValue",
                this.payload
            );
        }
    },
    created() {
        this.$store.dispatch(this.settings.action);
        if(this.filter.value !== null){
            this.choosed = this.filter.value
        }        
    },
    components: {
        MultiselectModal
    }
};
</script>