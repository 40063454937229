import Vue from "vue/dist/vue.esm";
import AdvertiserForm from "./vue/advertiser-form/main";
import store from "../vuex";

export default function() {
  if (document.querySelector("advertiser-form")) {
    return new Vue({
      el: "advertiser-form",
      components: { AdvertiserForm },
      store
    });
  }
}
