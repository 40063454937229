<template>
    <section>    
        <b-button 
            type="is-primary"
            v-if="!isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="createCustomer">
            Save
        </b-button>
        <b-button 
            type="is-primary"
            v-if="isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateCustomer">
            Save
        </b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState } from "vuex";
export default Vue.component("customer-saver", {    
    computed: {
        ...mapState({
            customer: state => state.CustomerStore.customer
        }),
        isEdit(){
            return this.customer.hasOwnProperty('id') && this.customer.id != null
        },
        isSavable() {
            return (
                this.customer.name !== ''
            );
        },
        payload(){
            return {
                ...this.customer,
                authorizations_attributes: [],
                users_attributes: [],
            };
        }          
    },
    methods: {
        async createCustomer(){
            this.$store.dispatch("CustomerStore/save", this.customer);
        },
        updateCustomer() {
            this.$store.dispatch("CustomerStore/update", this.customer);
        },
    }
});
</script>