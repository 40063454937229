import Vue from 'vue/dist/vue.esm'
import CategorySaver from './vue/category-form/saver'
import store from '../vuex';


export default function () {
  if (document.querySelector('category-saver')) {
    return new Vue({
      el: 'category-saver',
      components: { CategorySaver },
      store
    })
  }
}