import Vue from "vue/dist/vue.esm";
import CustomerForm from './vue/customer-form/main'
import store from "../vuex";

export default function() {
  if (document.querySelector("customer-form")) {
    return new Vue({
      el: "customer-form",
      components: { CustomerForm },
      store
    });
  }
}
