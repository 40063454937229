import Vue from 'vue/dist/vue.esm'
import PublisherSaver from './vue/publisher-form/saver'
import store from '../vuex';


export default function() {
  if (document.querySelector('publisher-saver')) {
    return new Vue({
      el: 'publisher-saver',
      components: { PublisherSaver },
      store
    })
  }
}