<template>
    <section>
        <b-button 
            type="is-primary"
            v-if="!isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="createDatabase">
            Save
        </b-button>
        <b-button 
            type="is-primary"
            v-if="isEdit"
            icon-left="save"
            :disabled="!isSavable"
            @click="updateDatabase">
            Save
        </b-button>
    </section>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import FormComponent from "../../form-component";
import { mapState } from "vuex";
export default Vue.component("database-saver", {
    extends: FormComponent,
    computed: {
        ...mapState({
            database: state => state.DatabaseStore.database
        }),
        isEdit(){
            return this.database.hasOwnProperty('id') && this.database.id != null
        },
        isSavable() {
            return (
                this.database.name !== ''
            );
        }          
    },
    methods: {
        createDatabase(){
            this.$store.dispatch("DatabaseStore/save", this.database);
        },
        updateDatabase() {
            this.$store.dispatch("DatabaseStore/update", this.database);            
        }
    }
});
</script>