import axios from "axios";
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";

const CategoryStore = {
  namespaced: true,
  state: {
    category: {
      code: "",
      name_it: "",
      name_en: "",
      parent_id: ""
    },
    errors: [],
  },
  mutations: {
    SET_CATEGORY(state, data) {
      state.category = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
      let i = 10000;
      Object.keys(data).map((k) => {
        Snackbar.open({
          duration: i,
          type: "is-danger",
          message: `${k} ${data[k][0]}`,
          position: "is-bottom-right",
          queue: false,
        });
        i = i + 1000;
      });
    },
    SEND_SUCCESS_MESSAGE(_state, msg) {
      Toast.open({
        message: msg,
        type: "is-success",
      });
    },
  },

  actions: {
    setCurrent({ commit }, payload) {
      commit("SET_CATEGORY", payload);
    },
    async save({ commit }, payload) {
      await axios
        .post("/api/v1/settings/categories", payload)
        .then(function (response) {
          commit("SEND_SUCCESS_MESSAGE", "Category Saved!")
          window.location.href = `/admin/settings/categories/${response.data.id}/edit`;
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    },
    async update({ commit }, payload) {
      await axios
        .put(`/api/v1/settings/categories/${payload.id}`, payload)
        .then(function (response) {
          commit("SEND_SUCCESS_MESSAGE", "Category Updated!")
          commit("SET_CATEGORY", response.data);
        })
        .catch(function (error) {
          commit("SET_ERRORS", error.response.data);
        });
    }
  },
};

export default CategoryStore;
