import Vue from 'vue/dist/vue.esm'
import ReportForm from './vue/report-form/main'
import store from "../vuex";

export default function() {
  if (document.querySelector('report-form')) {
    return new Vue({
      el: 'report-form',
      components: { ReportForm },
      store
    })
  }
}
