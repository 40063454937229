import Rails from "@rails/ujs";
import { DialogProgrammatic as Dialog } from "buefy";
import "../src/components/init";

const handleDelete = (event) => {
  Rails.stopEverything(event);
  let el = event.target;
  const customHandler = (el) => {
    el.removeAttribute("data-behaviour");
    el.click();
  };
  Dialog.confirm({
    title: `Deleting ${el.dataset.resource}`,
    message: `Are you sure you want to <b>delete</b> 
              this ${el.dataset.resource}? 
              This action cannot be undone.`,
    confirmText: "Delete",
    type: "is-danger",
    hasIcon: true,
    onConfirm: () => customHandler(el),
  });
};

const handleUnsubscrive = (event) => {
  Rails.stopEverything(event);  
  let el = event.target;
  let conftype = el.dataset.conftype || 'info';

  const customHandler = (el) => {
    el.removeAttribute("data-behaviour");
    el.click();
  };
  Dialog.confirm({
    title: el.dataset.title,
    message: el.dataset.text,
    confirmText: el.dataset.action,
    type: `is-${conftype}`,
    hasIcon: true,
    onConfirm: () => customHandler(el),
  });
};

const collapseSidebar = (event) => {
  Rails.stopEverything(event);
  let el = event.currentTarget.activeElement;
  let sidebar = document.getElementById(el.dataset.target);  
  let collapseclass = "is-collapsed";

  if(sidebar != null){    
    if(sidebar.classList.contains(collapseclass)){
      sidebar.classList.remove(collapseclass)
    }
    else{
      sidebar.classList.add(collapseclass)
    }
  }
};

Rails.delegate(document, 'a[data-behaviour="dialog"]', "click", handleDelete);
Rails.delegate(document, 'a[data-behaviour="confirm"]', "click", handleUnsubscrive);
Rails.delegate(document, 'a[data-action="collapse"]', "click", collapseSidebar);

Rails.start();

//require("@rails/activestorage").start()
//require("channels")