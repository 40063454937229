<template>
<div class="buttons">
    <b-button
        v-if="bsave && campaign.state == 'draft' && !show"
        class="is-marginless-right"
        icon-left="save"
        size="is-small"
        type="is-success"
        :disabled="!canSaveDraft"
        @click="saveDraft()"
    >SAVE DRAFT</b-button>
    <b-dropdown
        v-if="btest && !show"
        type="button"
        class="toolbar-item"
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        trap-focus
        :can-close="true"
        :disabled="!isSendable"
    >
        <button @click="resetSent()" class="button is-small" type="button" slot="trigger">
            <b-icon icon="paper-plane"></b-icon>
            <span>SEND TEST</span>
        </button>

        <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
            <div class="modal-card" style>
                <head class="modal-card-head">SEND A PREVIEW TEST</head>
                <section class="modal-card-body">
                    <b-field label="Add mail addresses" label-position="on-border">
                        <b-taginput
                            :maxtags="limit"
                            size="is-small"
                            v-model="mail_addresses"
                            ellipsis
                            icon="envelope"
                            placeholder="Add email"
                        ></b-taginput>
                    </b-field>
                </section>
                <footer v-if="canSendTest && !isSent" class="modal-card-foot">
                    <b-button size="is-small" type="is-primary" @click="sendTest()">SEND TEST</b-button>
                </footer>
            </div>
        </b-dropdown-item>
    </b-dropdown>

    <b-button
        v-if="bsave && bsend && campaign.state == 'draft' && !show"
        icon-left="paper-plane"
        size="is-small"
        type="is-primary"
        @click="sendCampaignApproval()"
    >SEND</b-button>
</div>
</template>


<script>
import Vue from "vue/dist/vue.esm";
import Buefy from "buefy";
import { mapState } from "vuex";
export default Vue.component("campaign-toolbar", {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        bsave: {
            type: Boolean,
            default: true,
        },
        btest: {
            type: Boolean,
            default: true,
        },
        bsend: {
            type: Boolean,
            default: true,
        },        
        
    },
    data() {
        return {
            mail_addresses: [],
            limit: 5,
            isSent: false,
        };
    },
    computed: {
        ...mapState({
            campaign: (state) => state.CampaignStore.campaign,
            contextUrl: (state) => state.CampaignStore.contextUrl,
        }),
        canSendTest() {
            return this.mail_addresses.length > 0;
        },
        hasName() {
            return (
                this.campaign.name !== undefined &&
                this.campaign.name.trim() !== ""
            );
        },
        canSaveDraft() {
            return this.campaign.type !== null && this.hasName;
        },
        isSendable() {
            return (
                this.canSaveDraft &&
                this.campaign.markup != null &&
                this.campaign.markup.trim() !== "" &&
                this.campaign.subject != null &&
                this.campaign.subject.trim() !== ""
            );
        },
    },
    methods: {
        saveDraft() {
            if (this.campaign.hasOwnProperty("id")) {
                this.$store.dispatch("CampaignStore/update", this.campaign);
            } else {
                this.$store.dispatch("CampaignStore/save", this.campaign);
            }
        },
        resetSent() {
            this.isSent = false;
            this.mail_addresses = [];
        },
        sendTest() {
            this.saveDraft()
            this.$store.dispatch("CampaignStore/setLoading", true);
            this.$store.dispatch("CampaignStore/send_test", {
                campaign: this.campaign,
                mail: this.mail_addresses,
            });
            this.isSent = true;
        },
        sendCampaignApproval() {
            this.$store.dispatch(
                "CampaignStore/sendApprovalRequest",
                this.contextUrl
            );
        },
    },
});
</script>