<template>
    <section>
        <card-box>
            <template slot="title">Campaign: "{{ campaign.name }}"</template>
            <template slot="title-right">
                <div class="buttons">
                    <b-button
                        v-if="!readonly"
                        @click="saveChanges()"
                        icon-left="save"
                        size="is-small"
                        type="is-primary"
                    >SAVE</b-button>
                </div>
            </template>
            <article class="media">
                <figure class="media-left has-text-centered">
                    <strong>{{ campaign.type | uppercase }}</strong>
                    <p class="image is-64x64">
                        <b-icon pack="fas" icon="envelope" size="is-large"></b-icon>
                    </p>
                </figure>
                <div class="media-content">
                    <nav class="level is-mobile">
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Customer</p>
                                <p class="title is-6">{{ resource.customer.name }}</p>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Advertiser</p>
                                <p class="title is-6">{{ resource.advertiser.name }}</p>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Segment</p>
                                <p class="title is-6">
                                    <b-tooltip
                                        :label="`usage ${campaign.usage} of ${segment_stat}`"
                                        dashed
                                    >{{ resource.segment.name }}</b-tooltip>
                                </p>
                            </div>
                        </div>
                        <div class="level-item pt-6 medium-width" v-if="campaign.usage < segment_stat">                            
                            <b-field label="FROM" label-position="on-border">
                                <b-input placeholder="Number"
                                    type="number"
                                    min="0"
                                    v-model="campaign.segment_from"
                                    :max="segment_stat - campaign.usage"
                                    :disabled="readonly">
                                </b-input>
                                <p class="control">
                                    <span class="button is-static"> / {{ parseInt(campaign.segment_from) + parseInt(campaign.usage) }}</span>
                                </p>
                            </b-field>
                        </div>

                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Volume</p>
                                <p class="title is-6">{{ campaign.usage }}</p>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Pay</p>
                                <p class="title is-6">{{ campaign.pay }}</p>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Model</p>
                                <p class="title is-6">{{ campaign.model || "SMS"  }}</p>
                            </div>
                        </div>
                    </nav>
                    <nav class="level is-mobile">
                        <div class="level-item">
                            <div class="has-text-centered">
                                <template v-if="campaign.type == 'mail'">
                                    <p class="heading" >Subject</p>
                                    <p class="title is-6" >{{ campaign.subject }}</p>    
                                </template>
                                <template v-if="campaign.type == 'sms'">
                                    <p class="heading">Text Message</p>
                                    <p class="title is-6">{{ campaign.message_text }}</p>                                    
                                </template>                                                                                                
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="has-text-centered">
                                <p class="heading">Category</p>
                                <p class="title is-6">{{ resource.category.name_en }}</p>
                            </div>
                        </div>
                    </nav>
                </div>
            </article>
        </card-box>
        <br />

        <div class="columns">
            <div class="column">
                <card-box :fullheight="true">
                    <template slot="title">
                        <i class="fas fa-calendar-alt" />&nbsp;
                        Customer Scheduling Info
                    </template>
                    <div>
                        <b-field label="Sent mode" label-position="on-border" expanded>
                            <b-select
                                size="is-small"
                                :value="sent_modes.customer"
                                placeholder="choose sent mode"
                                expanded
                                :disabled="true"

                            >
                                <option value="scheduled">Date/Time scheduled</option>
                                <option value="immediate">Immediate Sending</option>
                                <option
                                    value="split scheduled"
                                    disabled
                                >Split multiple days with percent</option>
                            </b-select>
                        </b-field>

                        <b-field
                            v-if="sent_modes.customer == 'scheduled'"
                            label="Scheduled for"
                            label-position="on-border"
                            expanded                            
                        >
                            <b-input
                                :value="delivery_dates.customer[0]"
                                type="text"
                                size="is-small"
                                :disabled="true"
                            ></b-input>
                        </b-field>
                    </div>
                </card-box>
            </div>
            <div class="column">
                <card-box>
                    <template slot="title">
                        <i class="fas fa-calendar-alt" />&nbsp;
                        Traffic Scheduling Info
                    </template>
                    <!-- <div v-if="readonly">
                        <ul>
                            <li>
                                <strong>sent mode:</strong>
                                <b-tag v-if="sent_modes.traffic">{{ sent_modes.traffic }}</b-tag>
                                <b-tag v-if="!sent_modes.traffic">NOT SET</b-tag>
                            </li>
                            <li>
                                <strong>scheduled for:</strong>
                                <span v-if="delivery_dates.traffic != null">
                                <template v-for="(day, index) in delivery_dates.traffic">
                                    <b-tag v-bind:key="`tday_${index}`">{{ day }}</b-tag>
                                </template>
                                </span> 
                                <b-tag v-if="delivery_dates.traffic == null">NOT SET</b-tag>
                            </li>
                        </ul>
                    </div>-->
                    <div>
                        <b-field label="Sent mode" label-position="on-border" expanded>
                            <b-select
                                size="is-small"
                                v-model="sent_mode"
                                placeholder="choose sent mode"
                                expanded
                                :disabled="readonly"
                            >
                                <option value="scheduled">Date/Time scheduled</option>
                                <option value="immediate">Immediate Sending</option>
                                <option
                                    value="split scheduled"
                                    disabled
                                >Split multiple days with percent</option>
                            </b-select>
                        </b-field>

                        <b-field
                            v-if="sent_mode == 'scheduled'"
                            label="Scheduled for"
                            label-position="on-border"
                            expanded
                        >
                            <b-datetimepicker
                                size="is-small"
                                v-model="choosed_date"
                                :min-datetime="minDate"
                                placeholder="Click to select..."
                                expanded
                                :disabled="readonly"
                            ></b-datetimepicker>
                        </b-field>
                    </div>
                </card-box>
            </div>
        </div>

        <card-box :paddingless="true" v-if="campaign.type == 'mail'">
            <template slot="title">
                <b-icon pack="fas" icon="file-code"></b-icon>Custom markups
            </template>
            <template slot="title-right">
                <b-button
                    v-if="isOpen != null"
                    size="is-small"
                    icon-left="eye"
                    @click="isComponentModalActive = true"
                >PREVIEW</b-button>
            </template>

            <b-collapse
                class="card"
                animation="slide"
                v-for="(collapse, index) of collapses"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
                @close="isOpen = null"
            >
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                    <div class="card-header-title">
                        <strong>DB "{{ collapse.name }}"</strong>&nbsp;
                        <strong class="has-text-info" v-if="!collapse.original">(MODIFIED)</strong>
                    </div>
                    <a class="card-header-icon">
                        <b-icon pack="fas" :icon="props.open ? 'chevron-down' : 'chevron-left'"></b-icon>
                    </a>
                </div>
                <div class="card-content">
                    <div class="content">
                        <b-input
                            @input="trackChanges(index)"
                            v-model="collapses[index].markup"
                            v-if="!readonly"
                            type="textarea"
                        ></b-input>
                        <iframe
                            width="100%"
                            height="100%"
                            v-if="readonly"
                            :srcdoc="collapses[index].markup"
                            style="min-height: 500px"
                            type="text/html"
                        >{{ collapses[index].markup }}</iframe>
                    </div>
                </div>
            </b-collapse>
        </card-box>

        <b-modal
            :active.sync="isComponentModalActive"
            :full-screen="false"
            has-modal-card
            :can-cancel="true"
        >
            <preview-component v-bind="currentMarkup"></preview-component>
        </b-modal>
    </section>
</template>

<script>
import FormComponent from "../../form-component";
import CardBox from "../elements/card-box";
import { mapState } from "vuex";
import moment from 'moment-timezone';

const PreviewComponent = {
    props: ["markup"],
    template: `
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Preview</p>
                </header>
                <section class="modal-card-body">
                    <iframe
                        width="100%"
                        style="min-height: 500px;width 100%;"
                        :srcdoc="markup"
                        type="text/html"
                    >markup</iframe>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="$parent.close()">Close</button>
                </footer>
            </div>
        `,
};

export default {
    extends: FormComponent,
    props: {
        resource: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: null,
            collapses: [],
            from: 0,
            isComponentModalActive: false,
            minDate: new Date(),
        };
    },
    computed: {
        ...mapState({
            campaign: (state) => state.CampaignStore.campaign,
        }),
        delivery_dates() {
            return this.$store.getters["CampaignStore/delivery_dates"];
        },
        sent_modes() {
            return this.$store.getters["CampaignStore/sent_modes"];
        },
        segment_stat() {
            return this.resource.segment.target_stats[this.campaign.type];
        },
        payload() {
            let creativities = this.collapses.map((c) => {
                delete c.name;
                c;
            });

            return {
                ...this.campaign,
                custom_creativities_attributes: this.collapses,
            };
        },
        currentMarkup() {
            if (this.isOpen !== null) {
                return {
                    markup: this.collapses[this.isOpen].markup,
                };
            }
            return { markup: "" };
        },
        sent_mode: {
            get() {
                return this.campaign.sent_mode_traffic;
            },
            set(val) {
                if (val == "immediate") {          
                    this.choosed_date = moment(new Date()).tz('Europe/Amsterdam', true).format()
                }

                this.campaign.sent_mode_traffic = val;                
            },
        },
        choosed_date: {
            get() {
                if (
                    Array.isArray(this.campaign.scheduling_traffic) &&
                    this.campaign.scheduling_traffic.length >= 1
                ) {
                    return new Date(this.campaign.scheduling_traffic[0].date);
                }
            },
            set(val) {
                this.campaign.scheduling_traffic = [
                    {
                        percent: 100,
                        date: val,
                    },
                ];
            },
        },
    },
    methods: {
        updateCampaign(payload) {
            this.$store.dispatch("CampaignStore/updateCampaign", payload);
        },
        trackChanges(i) {
            if (this.collapses[i].markup !== this.campaign.markup) {
                this.collapses[i].original = false;
            } else {
                this.collapses[i].original = true;
            }
        },
        saveChanges() {
            this.$store.dispatch(
                "CampaignStore/performTrafficSave",
                this.payload
            );
        },
    },
    mounted() {
        this.updateCampaign(this.resource);
        if (this.$attrs.hasOwnProperty("preload")) {
            this.collapses = this.$attrs.preload;
        }
    },
    components: {
        CardBox,
        PreviewComponent,
    },
};
</script>
