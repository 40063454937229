<template>
    <div>
        <div
            v-bind:key="`row_${index}`"
            class="level is-mobile"
            v-for="(row, index) in split_dates"
        >
            <div class="level-left">
                <div class="level-item">
                    <b-button
                        :disabled="split_dates.length <= 2"
                        size="is-small"
                        icon-right="times"
                        @click="removeDay(index)"
                    />
                </div>
                <div class="level-item">
                    <b-field
                        class="is-marginless-bottom"
                        label="Percentage"
                        label-position="on-border"
                        expanded
                    >
                        <b-input
                            v-model="split_dates[index].percent"
                            size="is-small"
                            placeholder="insert percentage"
                            type="text"
                            icon="percentage"
                            required
                        ></b-input>
                    </b-field>
                </div>
                <div class="level-item">
                    <b-field
                        class="is-marginless-bottom"
                        label="Select datetime"
                        label-position="on-border"
                        expanded
                    >
                        <b-datetimepicker
                            :key="`datepicker_${index}`"
                            size="is-small"
                            v-model="split_dates[index].date"
                            :min-datetime="minDate(index)"
                            placeholder="Click to select..."
                            expanded
                        ></b-datetimepicker>
                    </b-field>
                </div>
            </div>
            <div class="level-right">
                <b-button
                    v-if="index == 0"
                    size="is-small"
                    icon-right="plus"
                    :disabled="split_dates.length == 5"
                    @click="addDay()"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        preload: {
            type: Array,
            default: [
                { percent: 50, date: null },
                { percent: 50, date: null },
            ],
        },
    },
    data() {
        return {
            split_dates: this.preload,
        };
    },
    computed: {
        valid_rows() {
            return this.split_dates.filter((row) => {
                return row.date != null && row.date != "" && row.percent > 0;
            });
        },
        total_percentage() {
            return this.split_dates
                .map((row) => {
                    return row.percent;
                })
                .reduce((acc, val) => {
                    return acc + val;
                }, 0);
        },
        isValid() {
            if (this.valid_rows.length == this.split_dates.length) {
                return this.total_percentage == 100;
            }
            return false;
        },
    },
    watch: {
        split_dates: {
            deep: true,
            handler() {
                this.$emit("change", this.split_dates);
            },
        },
        isValid(){
            this.$emit("validation", this.isValid);
        }
    },
    methods: {
        addDay() {
            let empty_date = { percent: 0, date: null };
            this.split_dates.push(empty_date);
        },
        removeDay(index) {
            this.split_dates.splice(index, 1);
        },
        minDate(index) {
            let date = null;
            if (index == 0) {
                date = new Date();
            } else {
                date = this.split_dates[index - 1].date;
            }
            return new Date(new Date(date).getTime() + 60 * 60 * 24 * 1000);
        },
    },
};
</script>