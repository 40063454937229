import Vue from 'vue/dist/vue.esm'
import ReportDownloader from './vue/report-form/downloader'
import store from '../vuex';


export default function () {
  if (document.querySelector('report-downloader')) {
    return new Vue({
      el: 'report-downloader',
      components: { ReportDownloader },
      store
    })
  }
}